import vendorGetterModule from "./vendorModule/getters";
import marketplaceModuleGetters from "./marketplaceModule/getters";
import marketModuleGetters from "./marketModule/getters";
import lookupModuleGetters from "./lookupModule/getters";

export default {
  ...vendorGetterModule,
  ...marketplaceModuleGetters,
  ...marketModuleGetters,
  ...lookupModuleGetters,
};
