const setPreOrdersCartItems = (state, payload) => {
  if (payload) {
    const { data, totalData, totalPages, size } = payload;
    state.preOrdersCart = {
      data,
      paginationDetails: {
        totalData,
        totalPages,
        pageSize: size,
      },
    };
  } else {
    state.preOrdersCart = {
      data: [],
      paginationDetails: state.defaultPaginationDetails,
    };
  }
};

const removeProductFromDraftCart = (state, payload) => {
  state.preOrdersCart.data.splice(payload, 1);
};

export default {
  setPreOrdersCartItems,
  removeProductFromDraftCart,
};
