import { get } from "lodash";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import INVENTORY_TYPE_CONSTANTS from "@/constants/inventory-type-constants";

const createMedcenterOrder = async ({ commit }, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const inventoryType = get(payload, "inventoryType", null);
  const isINeedAKitOrder = inventoryType === INVENTORY_TYPE_CONSTANTS.fulfillmentCenterInventory;
  const query = isINeedAKitOrder ? "orders/v1/marketplace/medcenterOrder" : "orders/v1/medcenter/order";

  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Checkout successfully processed!",
  });

  if (response) {
    const { data } = response;
    commit("updatePatientOrders", {
      type: "ADD",
      result: data,
    });
    return data;
  }
};

const saveActivationCode = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = "/orders/v1/medcenter/activateCode";
  const response = await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Activation code entered successfully!",
    },
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  createMedcenterOrder,
  saveActivationCode,
};
