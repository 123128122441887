import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchPatientDetailsByLocIdAndPatientId = async ({ commit }, { locationId, patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setPatientDetails", data);
    return data;
  }
};

const fetchPatientLabsAppointment = async ({ commit }, { locationId, patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `patient/v1/location/${locationId}/patient/${patientId}/lab`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setPatientLabAppointments", {
      type: "init",
      data,
    });
    return data;
  }
};

const fetchIntelipelAppointments = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `patient/v1/location/${locationId}/appointment`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setAppointments", data);
    return data;
  }
};

const fetchDosingUserAppointments = async ({ commit }, { dosingUserId, locationId, fromDate, toDate }) => {
  const proxy = new GetSimpatraServiceProxy();
  let urlDateFields = fromDate ? `?fromDate=${fromDate}` : '';
  if (urlDateFields !== '' && toDate) {
    urlDateFields = `${urlDateFields}&toDate=${toDate}`;
  }
  const query = `patient/v1/location/${locationId}/dosing-user/${dosingUserId}/appointments${urlDateFields}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setAppointments", data);
    return data;
  }
};

const fetchPatientAppointments = async ({ commit }, { patientId, locationId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `patient/v1/location/${locationId}/patient/${patientId}/appointment`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setCurrentPatientAppointments", data);
    return data;
  }
};

const createNewAppointment = async ({ commit }, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const { locationId } = payload;
  const body = {
    patientId: payload.patientId,
    doctorId: payload.doctorId,
    type: payload.type,
    date: payload.date,
    status: "open",
  };
  const query = `patient/v1/location/${locationId}/appointment`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added appointment!",
  });

  if (response) {
    const { data } = response;
    commit("updateAppointmentList", data);
    return data;
  }
};

// Update
const updateAppointment = async ({ commit }, payload) => {
  const proxy = new PutSimpatraServiceProxy();
  const { locationId, aptId } = payload;
  const body = {
    patientId: payload.patientId,
    doctorId: payload.doctorId,
    type: payload.type,
    date: payload.date,
    status: payload.status,
    notes: get(payload, "notes", ""),
  };
  let query = `patient/v1/location/${locationId}/appointment/${aptId}`;
  const response = await proxy.execute(query, body, "Successfully updated appointment!");

  if (response) {
    const { data } = response;
    commit("updateAppointmentList", {
      updateProp: true,
      id: aptId,
      prop: "status",
      value: payload.status,
    });
    return data;
  }
};

const addNewPatientLab = async ({ commit }, payload) => {
  const { locationId, patientId } = payload;
  const body = {
    date: payload.date,
    values: payload.values,
  };
  const proxy = new PostSimpatraServiceProxy();
  const query = `/patient/v1/location/${locationId}/patient/${patientId}/lab`;
  const response = await proxy.execute(query, body, { errorNotificationMsg: "Unable to add patient lab." });
  if (response) {
    const { data } = response;
    commit("setPatientLabAppointments", {
      type: "add",
      data,
    });
    return data;
  }
};

export default {
  fetchPatientDetailsByLocIdAndPatientId,
  fetchPatientLabsAppointment,
  fetchIntelipelAppointments,
  fetchDosingUserAppointments,
  fetchPatientAppointments,
  createNewAppointment,
  updateAppointment,
  addNewPatientLab,
};
