import vendorModule from "./vendorModule/mutations";
import marketplaceModuleMutations from "./marketplaceModule/mutations";
import marketModuleMutations from "./marketModule/mutations";
import lookupModuleMutations from "./lookupModule/mutations";

export default {
  ...vendorModule,
  ...marketplaceModuleMutations,
  ...marketModuleMutations,
  ...lookupModuleMutations,
};
