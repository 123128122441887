const setPatientDetails = (state, payload) => {
  state.patientDetails = payload;
};

const setPatientLabAppointments = (state, { type, data }) => {
  if (type === "add") {
    state.patientLabAppointments.unshift(data);
  } else {
    state.patientLabAppointments = data;
  }
};

const setAppointments = (state, payload) => {
  state.appointments = payload;
};

const updateAppointmentList = (state, payload) => {
  if (payload.updateProp) {
    const selectedAppointmentIndex = state.appointments.findIndex((appointment) => appointment.id === payload.id);
    state.appointments[selectedAppointmentIndex][payload.prop] = payload.value;
  } else {
    state.appointments.push(payload);
  }
};

const setCurrentPatientAppointments = (state, payload) => {
  if (payload.type === "add") {
    state.currentPatientAppointments.unshift(payload.data);
  } else {
    state.currentPatientAppointments = payload;
  }
};

const setPatientRedirectBackTo = (state, payload) => {
  state.patientRedirectBackTo = payload;
};


export default {
  setPatientDetails,
  setPatientLabAppointments,
  setAppointments,
  updateAppointmentList,
  setCurrentPatientAppointments,
  setPatientRedirectBackTo,
};
