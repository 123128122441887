import { get, merge } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchReportList = async ({ commit }, { queryParams = {} }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `orders/v1/orders`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const responseData = response.data;
    const data = get(responseData, "data", []);
    const paginationDetails = {
      currentPage: queryParams.pageNumber + 1,
      totalPages: get(responseData, "totalPages", 0),
      size: get(responseData, "size", 0),
      totalData: get(responseData, "totalData", 0),
    };
    commit("setReportList", merge({ data }, paginationDetails));
  }
};

export default {
  fetchReportList,
};
