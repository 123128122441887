import axios from "@/lib/axios";
import notification from "@/utils/notifications";

// CONSTANTS
import STATUS_ID_CONSTANTS from "@/constants/status-id-constants";

export default {
  namespaced: true,
  state: {
    patientOrders: [],
    patientInvoice: [],
  },
  getters: {
    getPatientInvoice: (state) => {
      return state.patientInvoice;
    },
  },
  mutations: {
    setPatientInvoice(state, payload) {
      state.patientInvoice = payload;
    },
  },
  actions: {
    async getPatientInvoiceByOrderId({ commit }, { patientId, orderId, type = "patient" }) {
      try {
        const response = await axios.get(`orders/v1/patient/${patientId}/invoice/${orderId}?type=${type}`);
        if (response) {
          commit("setPatientInvoice", response.data);
          return response.data;
        }
      } catch (error) {
        notification.warning({
          description: error.response.data.error,
        });
        return null;
      }
    },
  },
};
