import { isEmpty } from "lodash";
import axios from "@/lib/axios";
import globalUtil from "@/utils/global";
import notification from "@/utils/notifications";

const defaultState = () => {
  return {
    id: "",
    name: "",
    description: "",
    sku: "",
    manufacturer: "",
    vin: "",
    slug: "",
    weight: 0,
    vendorId: "",
    weightTypeId: 0,
    cardVolume: "",
    inStock: false,
    limited: false,
    limitedQty: 0,
    minQtyForPurchase: 0,
    images: [],
    files: [],
    price: 0,
    productPricingId: "",
    boundByState: false,
    registrationRequired: false,
    categoryName: "",
    categorySlug: "",
    subcategoryName: "",
    subcategorySlug: "",
    addItemToCart: false,
  };
};

export default {
  namespaced: true,

  state: {
    product: defaultState(),
    addToCartQty: 1,
    subscribed: false,
    wholsesaleTierPricingId: "",
    collectionKitPrice: 0,
  },

  getters: {
    getCollectionKitPrice: (state) => {
      return state.collectionKitPrice;
    },
    getProduct: (state) => {
      return state.product;
    },
    getProductAction: (state, g, rootState, rootGetters) => {
      if (rootGetters.getUserLoggedInStatus === false) {
        return "sign-in";
      }
      const { registrationRequired } = state.product;
      const hasApprovedRegistration = rootGetters["locationRegistrations/getRegistrationsByVendorId"].some((r) => {
        return r.status === "APPROVED";
      });
      if (registrationRequired && hasApprovedRegistration === false) {
        return "register";
      }
      if (state.product.inStock === false) {
        return "notify";
      }
      return "add-to-cart";
    },
    getAddToCartQty: (state) => {
      return state.addToCartQty;
    },
    getSubscribed: (state) => {
      return state.subscribed;
    },
    getWholesaleTierPricingId: (state) => {
      return state.wholsesaleTierPricingId;
    },
  },

  mutations: {
    setProduct(state, payload) {
      state.product = payload;
    },
    clearProduct(state) {
      Object.assign(state.product, defaultState());
    },
    setAddToCartQty(state, payload) {
      state.addToCartQty = payload;
    },
    setCartItems(state) {
      state.addItemToCart = true;
    },
    setSubscribed(state, payload) {
      state.subscribed = payload;
    },
    setWholesaleTierPricingId(state, payload) {
      state.wholsesaleTierPricingId = payload;
    },
    setCollectionKitPrice(state, payload) {
      state.collectionKitPrice = payload.collectionKitPrice;
    },
  },

  actions: {
    commitCollectionKitPrice: async ({ commit }) => {
      const payload = {
        collectionKitPrice: 0,
      };
      commit("setCollectionKitPrice", payload);
    },
    fetchCollectionKitPriceByProducts: async ({ commit }, payload) => {
      const response = await axios.post("/orders/v1/marketplace/collectionKit", payload);
      commit("setCollectionKitPrice", response.data);
    },
    fetchCollectionKitPriceBySku: async ({ commit }, payload) => {
      const { orgId } = payload;
      const mfgSku = payload.sku;
      const response = await axios.get(`/orders/v1/marketplace/org/${orgId}/sku/${mfgSku}`);
      commit("setCollectionKitPrice", response.data);
    },
    fetchProductBySku: async ({ commit }, payload) => {
      const { sku } = payload;
      const response = await axios.get(`/products/v1/market/product/sku/${sku}`);
      commit("setProduct", response.data);
    },
    fetchWholeSalePricingTierId: async ({ commit }, payload) => {
      const { vendorId, productId } = payload;
      const response = await axios.get(`/products/v1/market/vendor/${vendorId}/product/${productId}/productPricing`);
      commit("setWholesaleTierPricingId", response.data);
    },
    commitItemToCart: async ({ commit }, payload) => {
      const products = [];
      payload.forEach((item) => {
        products.push(item);
      });
      commit("setCartItems", products, { root: true });
      commit("setAddToCartQty", 1);
    },
    fetchProductBySlug: async ({ commit }, { vin, slug, orgId }) => {
      let response = null;
      if (orgId) {
        response = await axios.get(`/products/v1/market/product/${vin}/${slug}?orgId=${orgId}`);
      } else {
        response = await axios.get(`/products/v1/market/product/${vin}/${slug}`);
      }
      commit("setProduct", response.data);
    },
    fetchProductById: async ({ commit }, productId) => {
      const response = await axios.get(`/products/v1/market/product/${productId}`);
      commit("setProduct", response.data);
    },
    addItemToCart: async ({ commit, rootGetters, dispatch }, data) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: data.productId,
          productPricingId: data.productPricingId,
          qty: data.qty,
        };
        const response = await axios.post(`/orders/v1/cart/${data.locationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", 1);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
    addLocalProductsToCart: async ({ commit, getters, rootGetters, dispatch }, payload) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: payload.qty,
        };
        const response = await axios.post(`/orders/v1/cart/${payload.locationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", getters.getProduct.minQtyForPurchase);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
    addToCart: async ({ commit, getters, rootGetters, dispatch }) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: getters.getSelectedProduct.id,
          qty: getters.getAddToCartQty,
        };
        const response = await axios.post(`/orders/v1/cart/${rootGetters.getCurrentSelectedLocation}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", getters.getProduct.minQtyForPurchase);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
    addToCartForList: async ({ commit, dispatch, getters, rootGetters }, payload) => {
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      const isLocationActive = globalUtil.isLocationActive(payload.locationId);

      if (isLocationActive) {
        const { locationId } = payload;
        const body = {
          productId: payload.productId,
          qty: payload.qty,
        };
        const response = await axios.post(`/orders/v1/cart/${locationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", getters.getProduct.minQtyForPurchase);
      } else {
        notification("warning", "Selected location deactivated!");
      }
    },
    addLabarotaryKitToCart: async ({ getters }, payload) => {
      const body = {
        productId: payload.productId,
        productPricingId: getters.getWholesaleTierPricingId,
        qty: 1,
        patientId: payload.patientId,
        openOrderId: payload.openOrderId,
      };
      const { locationId } = payload;
      const response = await axios.post(`/orders/v1/cart/${locationId}/kit`, body);
    },
    subscribeToNotifications: async ({ commit, getters, rootGetters }, payload) => {
      const body = {
        productId: !isEmpty(payload) ? payload.productId : getters.getProduct.id,
        userId: rootGetters["usersModule/getMyUserId"],
      };

      const response = await axios.post("backorder/v1/expectant", body);
      if (response.status === 200) {
        commit("setSubscribed", true);
      }
    },
    commitSetAddToCartQty: async ({ commit }, qty) => {
      commit("setAddToCartQty", qty);
    },
    commitClearProduct: async ({ commit }) => {
      commit("clearProduct");
      commit("setSubscribed", false);
      commit("setAddToCartQty", 1);
    },
  },
};
