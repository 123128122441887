import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchSubscriptionsDetail = async ({ commit }, subscriptionId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `subscription/v1/subscription/${subscriptionId}`;
  const response = await proxy.execute(query);
  console.log(response, "response");
  if (response) {
    const { data } = response;
    commit("setSubscriptionsDetail", data);
  }
};
export default {
  fetchSubscriptionsDetail,
};
