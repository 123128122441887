// import PutSimpatraServiceProxy from '@/proxies/back-end/simpatra-service/put';
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
// import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
// import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
// import moduleConstructors from "./constructors";

const fetchStoreProduct = async (_, { orgId, queryParams }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/marketplace/org/${orgId}/products`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchProductById = async (_, productId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/market/product/${productId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchStoreProduct,
  fetchProductById,
};
