import { get, isEmpty, some } from "lodash";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";

const setCurrentLoggedInInfo = (state, payload) => {
  state.exp = payload.exp;
  state.access_token = payload.access_token;
  state.currentLoggedInInfo = payload.decodedToken;
  if (isEmpty(payload.decodedToken)) {
    state.currentUserPermissions = {};
  } else {
    const { sim, superAdminPermissionDetails } = payload.decodedToken;
    const locationPermissions = get(sim.details, "locationPermissions", []);
    const orgPermissions = get(sim.details, "orgPermissions", []);
    const userType = get(sim.user, "userType", "");
    const isSimpatraAdmin = get(sim, "simpatraAdmin", false);
    let isOrgAdmin = get(sim.details, "isAdmin", false);
    let isOrgOwner = get(sim.details, "isOwner", false);
    let isLocationAdmin =
      some(locationPermissions, (permission) => {
        return permission.name === "LocAdmin" && permission.value === 2;
      }) || isSimpatraAdmin;
    let isLocationProfileAdmin = some(locationPermissions, (permission) => {
      return permission.name === "LocProfile" && permission.value === 2;
    });
    let isLocationRegistrationAdmin = some(locationPermissions, (permission) => {
      return permission.name === "LocRegistration" && permission.value === 2;
    });
    let isLocationPaymentAdmin = some(locationPermissions, (permission) => {
      return permission.name === "LocPayment" && permission.value === 2;
    });
    let isLocationLicenseAdmin = some(locationPermissions, (permission) => {
      return permission.name === "LocLicenses" && permission.value === 2;
    });

    let isSchedulingAdmin = some(locationPermissions, (permission) => {
      return permission.name === "LocScheduling" && permission.value === 2;
    });
    const isOrgDirectoryAdmin = some(orgPermissions, (permission) => {
      return permission.name === "OrgDirectory" && permission.value === 2;
    });
    const isOrgApproverAdmin = some(orgPermissions, (permission) => {
      return permission.name === "Approver" && permission.value === 2;
    });
    let isConsultant = get(sim, "simpatraConsultant", false);
    const isStandardUser = !isSimpatraAdmin && !isOrgAdmin && !isLocationAdmin;
    const isVendorUser = userType === USER_TYPE_CONSTANTS.vendorUser;
    let isSubSuperAdmin = false;
    if (!isEmpty(superAdminPermissionDetails) && superAdminPermissionDetails.superAdminPermissionDetails) {
      superAdminPermissionDetails.superAdminPermissions.forEach((element) => {
        isSubSuperAdmin = element.value > 0;
      });
    }

    let permissions = {
      isSimpatraAdmin,
      isConsultant,
      isOrgAdmin,
      isLocationAdmin,
      isStandardUser,
      isOrgOwner,
      isVendorUser,
      isSubSuperAdmin,
      isLocationProfileAdmin,
      isLocationRegistrationAdmin,
      isLocationPaymentAdmin,
      isLocationLicenseAdmin,
      isOrgDirectoryAdmin,
      isOrgApproverAdmin,
      isSchedulingAdmin,
    };
    state.currentUserPermissions = permissions;
  }
};

export default {
  setCurrentLoggedInInfo,
};
