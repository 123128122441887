import { isEmpty, get } from "lodash";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import userMe from "@/services/user-me";

export default {
  state: {
    userType: "",
    landingPageType: "",
    labTestPageLoaded: false,
    id: "",
    name: "",
    newUserId: "",
    isActive: false,
    paymentsCard: [],
    details: {
      id: "",
      activeLocations: [],
    },
    brand: {
      name: "",
      // logo: '',
      logo: "https://simpatra-img.s3.us-west-2.amazonaws.com/product/1611239338153-simpatra-logo.png",
      fav: "",
    },
    dosing: {
      detail: {
        freeDoses: 0,
        maxDoses: 0,
        numDosingUsers: 0,
        billingGracePeriod: 0,
        pricePerDose: 0,
        paymentMethodId: "",
      },
      users: [],
    },
    availableCards: [],
    status: false,
    notifyUsers: [],
    bhrtPageLoaded: false,
    userAccountSetUp: null,
    finalizeSetUpData: {},
    activeLocations: [],
  },

  getters: {
    isLabTestPageLoaded(state) {
      return state.labTestPageLoaded;
    },
    isBHRTPageLoaded(state) {
      return state.bhrtPageLoaded;
    },
    getNotificationsUsers(state) {
      return state.notifyUsers;
    },
    getBrandDetails(state) {
      return state.brand;
    },
    getErrorStatus: (state) => {
      return state.status;
    },
    getAllLocationCardAccounts: (state) => {
      return state.paymentsCard.sort((a, b) => {
        if (a.shareable > b.shareable) {
          return -1;
        }
        if (a.shareable < b.shareable) {
          return 1;
        }
        return 0;
      });
    },
    getOrgDosingUsers: (state) => {
      return state.dosing.users;
    },
    getOrgDosingDetail: (state) => {
      return state.dosing.detail;
    },
    getOrgId: () => {
      return localStorage.getItem("orgId") || "";
    },
    getNewUserId: (state) => {
      return state.newUserId;
    },
    getActiveLocations: (state) => {
      if (isEmpty(state.activeLocations)) return [];
      return state.activeLocations.filter((location) => {
        return location.isActive === true;
      });
    },
    getCurrentOrg: (state) => {
      return state;
    },
    getAvailableOrgCards: (state) => {
      return state.availableCards;
    },
    getUserHasDosingAccess: (state) => {
      const isSimpatraAdmin = userMe.getMyRoles().isSimpatraAdmin;
      const hasDosingAccess =
        userMe.getMyRoles().isSchedulingAdmin > 0 && state.orgDosingDetails.numDosingUsers > 0;
      return isSimpatraAdmin || hasDosingAccess;
    },
    getLandingPageType: (state) => {
      return state.landingPageType;
    },
    // VERIFY IF WE CAN REMOVE
    getUserType: (state) => {
      return state.userType;
    },
    getUserAccountSetUp: (state) => {
      return state.userAccountSetUp;
    },
    getFinalizeSetUpData: (state) => {
      return state.finalizeSetUpData;
    },
  },

  mutations: {
    setUserForNotifications(state, payload) {
      state.notifyUsers = payload;
    },
    setBrandDetails(state, payload) {
      state.brand = payload;
    },
    setError(state, payload) {
      state.status = payload;
    },
    // setSuperAdminPermission(state, payload) {
    //   state.details.allUsers.map((user) => {
    //     if (user.id === payload.id) {
    //       return payload;
    //     }
    //     return user;
    //   });
    // },
    setNewUserId(state, payload) {
      state.newUserId = payload;
    },
    setAvailableOrgCards(state, payload) {
      state.availableCards = payload;
    },
    setAllLocationCardAccounts(state, payload) {
      // payload[0].data.forEach((row) => {
      //   if (row.buyerId === payload[1]) {
      //   } else {
      //     state.paymentsCard.push(row);
      //   }
      // });
      payload[0].data.forEach((row) => {
        state.paymentsCard.push(row);
      });
    },

    removePreviousAllLocationCard(state, payload) {
      state.paymentsCard = payload;
    },
    labtestPageLoaded(state, payload) {
      state.labTestPageLoaded = payload;
    },
    bhrtPageLoaded(state, payload) {
      state.bhrtPageLoaded = payload;
      // state.labTestPageLoaded = payload;
    },
    setLandingPageType(state, payload) {
      state.landingPageType = payload;
    },
    setUserType(state, payload) {
      state.userType = payload;
    },
    setUserAccountSetUp(state, payload) {
      state.userAccountSetUp = payload;
    },
    setFinalizeSetUpData(state, payload) {
      state.finalizeSetUpData = payload;
    },
  },

  actions: {
    commitProviderNetwork: async ({ commit }, payload) => {
      const { orgId } = payload;
      const body = {
        providerNetwork: payload.providerNetwork,
        myNetwork: payload.myNetwork,
      };
      const response = await axios.put(
        // `/accounts/v1/org/${orgId}/providernetwork/${providerNetwork}`,
        `/accounts/v1/org/${orgId}/providernetwork`,
        body
      );
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    commitUserType: async ({ commit }, payload) => {
      commit("setUserType", payload);
    },
    commitLandingPageType: async ({ commit }, payload) => {
      commit("setLandingPageType", payload);
    },
    commitlabTestPageLoaded: async ({ commit }) => {
      commit("labtestPageLoaded", true);
    },
    commitBhrtPageLoaded: async ({ commit }) => {
      commit("bhrtPageLoaded", true);
    },
    removeUserFromNotification: async ({ commit }, payload) => {
      const { orgId, orgEmailId } = payload;

      const response = await axios.delete(`accounts/v1/org/${orgId}/orgemail/${orgEmailId}`);
      commit("setUserForNotifications", response.data);
    },
    updateEmailForNotifications: async ({ commit }, payload) => {
      const { orgId } = payload;
      const body = {
        orgId,
        groupId: payload.groupId,
        email: payload.email,
        userId: payload.userId,
      };
      const response = await axios.post(`/accounts/v1/org/${orgId}/orgemail`, body);
      commit("setUserForNotifications", response.data);
    },
    fetchAllUserForNotifications: async ({ commit }, payload) => {
      const response = await axios.get(`accounts/v1/org/${payload.orgId}/orgemail`);
      commit("setUserForNotifications", response.data);
    },
    fetchBrandingDetails: async ({ commit }, payload) => {
      const practiceId = payload.orgId;
      const response = await axios.get(`accounts/v1/org/images/${practiceId}`);
      commit("setBrandDetails", response.data);
    },
    updateBrandLogoAndFavicon: async ({ commit }, payload) => {
      const orgId = payload.id;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
        logoimgUrl: payload.logoimgUrl,
        favimgUrl: payload.favimgUrl,
      };
      const response = await axios.put(`/accounts/v1/org/${orgId}`, body);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    removePreviousAllLocationCard: async ({ commit }, data) => {
      commit("removePreviousAllLocationCard", data);
    },
    fetchAllOrgUsers: async ({ commit }, orgId) => {
      const response = await axios.get(`/accounts/v1/org/${orgId}/user`);
    },
    fetchOrgUserDetails: async (_, { orgId, userId }) => {
      try {
        const response = await axios.get(`/accounts/v1/org/${orgId}/user/${userId}`);
        if (response) {
          const { data } = response;
          return data;
        }
      } catch (error) {
        notification("warning", error.response.data.message);
        return null;
      }
      // TODO: commit
    },
    fetchAllOrgs: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/org");
      commit("setAvailableOrgs", response.data);
    },
    // Update
    updateOrgUser: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { userId } = payload;
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        email: payload.email,
      };

      await axios.put(`/accounts/v1/org/${orgId}/user/${userId}`, body);
      dispatch("accountsModule/fetchOrgDetails", { orgId }, { root: true });
    },
    setUserLocations: async ({ dispatch }, payload) => {
      const { locationIdArray } = payload;
      const { orgId } = payload;
      const { userId } = payload;
      await axios.put(`/accounts/v1/org/${orgId}/user/${userId}/access`, locationIdArray);
      dispatch("accountsModule/fetchOrgDetails", { orgId }, { root: true });
    },
    removeUserFromDosing: async ({ commit }, payload) => {
      const { orgId, userId } = payload;
      const response = await axios.delete(`accounts/v1/org/${orgId}/dosing/user/${userId}`);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    addUserToDosing: async ({ commit }, payload) => {
      const { orgId, userId } = payload;
      const response = await axios.post(`accounts/v1/org/${orgId}/dosing/user/${userId}`);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    updateOrgDetails: async ({ commit }, payload) => {
      const orgId = payload.id;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      const response = await axios.put(`/accounts/v1/org/${orgId}`, body);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },

    // Add
    addOrgUser: async (_, payload) => {
      const { orgId } = payload;
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
      };

      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/user`, body);
        if (response) {
          const { data } = response;
          return data;
        }
      } catch (error) {
        notification("warning", error.response.data.message);
        return null;
      }
    },
    addNewOrg: async ({ commit }, payload) => {
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
        user: {
          userId: payload.user.userId,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          email: payload.user.email,
          phone: payload.user.phone,
        },
      };
      const response = await axios.post("/accounts/v1/org/", body);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    // Delete
    deleteOrgUser: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { userId } = payload;

      await axios.delete(`/accounts/v1/org/${orgId}/user/${userId}?delete=1`);
      dispatch("accountsModule/fetchOrgDetails", { orgId }, { root: true });
    },
    fetchAllLocationCardAccounts: async ({ commit, rootState }, data) => {
      const location = rootState.org.details.activeLocations.filter((loc) => {
        return loc.id === data.buyerId;
      });

      const { orgId } = data;
      const { buyerId } = data;
      const resData = [];

      const response = await axios.get(`/payments/v1/card/buyer/${buyerId}/method`);

      resData.push(response);
      resData.push(orgId);
      if (resData[0].data.length > 0) {
        resData[0].data[0].location = location[0] && location[0].name;
      }

      commit("setAllLocationCardAccounts", resData);
    },

    // Payment Methods

    fetchAllOrgCardAccounts: async ({ commit }, orgId) => {
      const response = await axios.get(`/payments/v1/card/buyer/${orgId}/method`);
      commit("setAvailableOrgCards", response.data);
    },

    addOrgCardAccount: async ({ commit }, payload) => {
      const { orgId } = payload;
      const body = {
        cardholderName: payload.cardholderName,
        cardNumber: payload.cardNumber,
        expDate: payload.expDate,
        cardType: payload.cardType,
        billingAddress: payload.billingAddress,
        cvv2: payload.cvv2,
        shareable: payload.shareable,
      };

      const response = await axios.post(`/payments/v1/card/buyer/${orgId}`, body);
      commit("setAvailableOrgCards", response.data);
      return response && response.data;
    },
    updateOrgCardAccount: async ({ commit }, payload) => {
      const body = {
        isShare: payload.shareable,
      };
      const { shareable, paymentMethodId, buyerId } = payload;

      const response = await axios.put(
        `/payments/v1/card/buyer/${buyerId}/method/${paymentMethodId}/share/${shareable}`,
        body
      );
      commit("setAvailableOrgCards", response.data);
      if (response) {
        return response.data;
      }
      return null;
    },

    removeOrgCardAccount: async (_, payload) => {
      const { orgId, accountId } = payload;
      const response = await axios.delete(`/payments/v1/card/buyer/${orgId}/method/${accountId}`);
      if (response) {
        return response;
      }
      return null;
    },
    checkUserAccountSetUp: async ({ commit }, userId) => {
      const response = await axios.get(`/accounts/v1/org/user/check/${userId}`);
      commit("setUserAccountSetUp", response.data);
    },
    // UPDATE CODE BLOCKS
    updateFinalizeSetUpData: async ({ commit }, payload) => {
      const userId = payload.id;
      const { password } = payload;
      const response = await axios.put(`/accounts/v1/org/user/check/${userId}/update/${password}`);
      commit("setFinalizeSetUpData", response.data);
    },
    // updateOrgUser: async ({ dispatch }, payload) => {
    //   const { orgId } = payload;
    //   const { userId } = payload;
    //   const body = {
    //     firstName: payload.firstName,
    //     lastName: payload.lastName,
    //     phone: payload.phone,
    //     email: payload.email,
    //   };

    //   return await accountsApi.updateOrgUser({ orgId, userId }, body);
    // },
  },
};
