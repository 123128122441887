import { merge } from "lodash";
import fulfillmentModuleActions from "./fulfillment/actions";
import activateKitModuleActions from "./activateKit/actions";
import barcodeModuleActions from "./barcode/actions";
import vendorModuleActions from "./vendorModule/actions";
import buyerModuleActions from "./buyerModule/actions";
import patientModuleActions from "./patient/actions";
import activationCodeModuleActions from "./activationCodeModule/actions";
import marketplaceModuleActions from "./marketplaceModule/actions";
import medcenterModuleActions from "./medcenterModule/actions";
import laboratoryModuleActions from "./laboratoryModule/actions";
import cartModuleActions from "./cartModule/actions";
import purchaseModuleActions from "./purchaseModule/actions";
import ordersModuleActions from "./ordersModule/actions";
import orgModuleActions from "./orgModule/actions";

export default merge(
  fulfillmentModuleActions,
  activateKitModuleActions,
  barcodeModuleActions,
  vendorModuleActions,
  buyerModuleActions,
  patientModuleActions,
  activationCodeModuleActions,
  marketplaceModuleActions,
  medcenterModuleActions,
  laboratoryModuleActions,
  cartModuleActions,
  purchaseModuleActions,
  ordersModuleActions,
  orgModuleActions
);
