import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchKitProductByActivationCode = async (_, activationCode) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/activationcode/${activationCode}`;
  const response = await proxy.execute(query, null, {
    notificationParams: {
      hideErrorNotif: false,
    },
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchKitProductByActivationCode,
};
