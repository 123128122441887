import { merge } from "lodash";
import vendorModuleGetters from "./vendorModule/getters";
import buyerModuleGetters from "./buyerModule/getters";
import patientModuleGetters from "./patient/getters";
import activationCodeModuleGetters from "./activationCodeModule/getters";
import ordersModuleGetters from "./ordersModule/getters";
import orgModuleGetters from "./orgModule/getters";
import cartModuleGetters from "./cartModule/getters";

export default merge(
  vendorModuleGetters,
  buyerModuleGetters,
  patientModuleGetters,
  activationCodeModuleGetters,
  ordersModuleGetters,
  orgModuleGetters,
  cartModuleGetters
);
