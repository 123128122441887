const getSocialMediaList = (state) => {
  return state.socialMediaList;
};

const getOrganizationDetails = (state) => {
  return state.organizationDetails;
};

export default {
  getSocialMediaList,
  getOrganizationDetails,
};
