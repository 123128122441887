import { merge } from "lodash";
import vendorModuleState from "./vendorModule/state";
import buyerModuleState from "./buyerModule/state";
import patientModuleState from "./patient/state";
import activationCodeModuleState from "./activationCodeModule/state";
import ordersModuleState from "./ordersModule/state";
import orgModuleState from "./orgModule/state";
import cartModuleState from "./cartModule/state";

export default merge(
  vendorModuleState,
  buyerModuleState,
  patientModuleState,
  activationCodeModuleState,
  ordersModuleState,
  orgModuleState,
  cartModuleState
);
