import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchFulfillmentLineItems = async (_, fulfillmentId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/fulfillment/${fulfillmentId}/items`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchFulfillmentByFulfillmentId = async (_, fulfillmentId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/fulfillment/${fulfillmentId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatientFulfillments = async (
  _,
  { locationId, patientId, pageNumber = 0, pageSize = 10, paginate = false }
) => {
  const proxy = new GetSimpatraServiceProxy();
  let queryParams = {};
  if (paginate) {
    queryParams.pageNumber = pageNumber;
    queryParams.pageSize = pageSize;
  }
  const query = `orders/v1/fulfillment/location/${locationId}/patient/${patientId}`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchFulfillmentLineItems,
  fetchFulfillmentByFulfillmentId,
  fetchPatientFulfillments,
};
