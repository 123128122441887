// import PutSimpatraServiceProxy from '@/proxies/back-end/simpatra-service/put';
import { get } from "lodash";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import moduleConstructors from "../marketplaceModule/constructors";

const updateProduct = async ({ state }, { vendorId, payload }) => {
  const isBundleFlag = get(payload, "ispackage", false);
  const productId = state.productDetailsForm.id;

  const body = moduleConstructors.constructProductPayload(payload, isBundleFlag);
  const proxy = new PutSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product/${productId}`;
  const response = await proxy.execute(query, body, "Successfully updated product!");
  if (response) {
    const { data } = response;
    if (data) {
      let resultsData = Object.assign({}, data);
      if (resultsData.productBundle.length > 0) {
        const value = [];
        resultsData.productBundle.forEach((product) => {
          value.push(product.childProduct);
        });

        resultsData.productBundle = value;
        // commit("setProductFormDetails", resultsData);
      }

      return data;
    }
  } else {
    return null;
  }
};

const updateProductStocksStatus = async (_, { vendorId, productId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product/${productId}/stock`;
  const response = await proxy.execute(query, payload, {
    successNotificationMsg: `Product succesfully updated to ${payload.inStock ? "In Stock" : "Out of Stock"}`,
    displaySuccessMessage: true,
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const addNewProduct = async (_, { payload, vendorId }) => {
  const isBundleFlag = get(payload, "ispackage", false);

  const body = moduleConstructors.constructProductPayload(payload, isBundleFlag);
  const proxy = new PostSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product`;
  const response = await proxy.execute(query, body, {
    successNotificationMsg: "Successfully added product!",
    displaySuccessMessage: true,
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const addNewBundleProduct = async (_, { payload, vendorId }) => {
  const isBundleFlag = get(payload, "ispackage", false);

  const body = moduleConstructors.constructProductPayload(payload, isBundleFlag);
  const proxy = new PostSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product`;
  const response = await proxy.execute(query, body, {
    successNotificationMsg: "Successfully added product!",
    displaySuccessMessage: true,
  });
  if (response) {
    const { data } = response;
    let resultsData = Object.assign({}, data);
    if (resultsData.productBundle.length > 0) {
      const value = [];
      resultsData.productBundle.forEach((product) => {
        value.push(product.childProduct);
      });

      resultsData.productBundle = value;
      // commit("setProductFormDetails", resultsData);
    }

    return data;
  }
};

const fetchVendorProducts = async (_, { queryParams, vendorId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/vendor/${vendorId}/product`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
  return [];
};

export default {
  updateProduct,
  updateProductStocksStatus,
  addNewProduct,
  addNewBundleProduct,
  fetchVendorProducts,
};
