// eslint-disable-next-line import/no-cycle
import { isEmpty } from 'lodash';
import notification from '@/utils/notifications';
import axios from '../lib/axios';

export default {
  namespaced: true,
  state: {
    storeProducts: [],
    landingPageProducts: [],
    selectedProducts: [],
    domain: '',
    vendors: [],
    storeDetails: {
      commission: 0,
      domainName: '',
      prodIds: '',
      domain: 'labtest.health',
      selectedProducts: [],
    },
    onlineStorePage: 1,
    onlineStorePageLength: 12,
    kits: [],
    individuals: [],
    panels: [],
  },

  getters: {
    getStoreProducts: (state) => { return state.storeProducts; },
    getStoreDetails: (state) => { return state.storeDetails; },
    getAllLandPageVendors: (state) => { return state.vendors; },
    getAllCheckedProducts: (state) => { return state.selectedProducts; },
    getCurrentDomain: (state) => { return state.domain; },
    getNumberOfPages(state, getters) {
      const pages = Math.ceil(
        getters.getStoreProducts.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.onlineStorePageLength;
    },
    getPage(state) {
      return state.onlineStorePage;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getStoreProducts;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getActiveKits(state) {
      return state.kits;
    },
    getActivePanels(state) {
      return state.panels;
    },
    getActiveIndividuals(state) {
      return state.individuals;
    },
  },

  mutations: {
    setOnlineStorePage(state, payload) {
      state.onlineStorePage = payload;
    },
    setStoreDetails(state, payload) {
      state.storeDetails = payload;
    },
    setAllLandPageVendors(state, payload) {
      state.vendors = payload;
    },
    setAllCheckedProducts(state, payload) {
      state.selectedProducts = payload;
    },
    setCurrentDomain(state, payload) {
      state.domain = payload;
    },
    setOnlineStoreProducts(state, payload) {
      if (!isEmpty(payload)) {
        state.storeProducts = payload;
      } else {
        state.storeProducts = [];
      }
    },
    updateProduct(state, payload) {
      const index = state.storeProducts.findIndex(
        (item) => { return item.id === payload.productId; },
      );
      state.storeProducts[index].isForSale = payload.value;
    },
    // UPDATING PRACTICE PRICE ONCE SAVED
    updatePracticeProduct(state, payload) {
      const productIndex = state.storeProducts.findIndex((item) => { return item.id === payload.product.id; });
      state.storeProducts[productIndex].product = payload.price.toFixed(2);
    },
    // DESTRUCTURING PRODUCTS
    initializeLabProducts(state, { type, payload }) {
      state[type] = payload;
    },
    updateProductDisbledProperty(state, { type, payload }) {
      state[type][payload.index].isDisabled = payload.value;
    },
    clearDisabledProduct(state) {
      state.individuals.forEach((product) => { return product.isDisabled = false; });
      state.panels.forEach((product) => { return product.isDisabled = false; });
    },
  },

  actions: {
    nextPage({ getters, commit }) {
      commit('setOnlineStorePage', getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit('setOnlineStorePage', getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit('setOnlineStorePage', payload);
    },
    fetchSearchedProducts: async ({ commit }, payload) => {
      const queryString = payload.searchQuery;
      let response;
      if (queryString !== '') {
        response = await axios.get(
          `/products/v1/market/lsearch?q=${queryString}`,
        );
      } else {
        response = await axios.get(
          '/products/v1/vendor/landproducts',
        );
      }
      // commit('setOnlineStoreProducts', response.data);
      return response.data;
    },
    fetchAllLandPageVendors: async ({ commit }) => {
      const response = await axios.get(
        '/products/v1/vendor/all/landproducts',
      );
      commit('setAllLandPageVendors', response.data);
    },
    // -----------------------------------------------------------------------------------|
    // ================== GET PRACTICE BUILDER ONLINE STORE PRODUCT ======================|
    fetchAllStoreProductsByVendorId: async ({ commit }, vendorId) => {
      const response = await axios.get(
        `/products/v1/vendor/${vendorId}/landproducts`,
      );

      return response.data;
    },
    fetchOrderLabProducts: async (_, orgId) => {
      const response = await axios.get(
        `products/v1/medcenter/org/${orgId}/products`,
      );

      const result = response.data.map((item) => {
        item.productPricing = [{
          pricingTier: {
            name: 'Retail Pricing',
          },
          finalPrice: 5.5,
        }];

        if (item.categoryName === 'Medical Supplies') {
          item.mfgSkuVol = parseInt(item.name);
        } else {
          item.mfgSkuVol = 150;
        }

        return item;
      });

      return result;
    },
    // ======================================= END =======================================|
    // -----------------------------------------------------------------------------------|
    // ================================== UPDATE PRODUCTS ================================|
    updateStoreDetails: async (_, payload) => {
      const { orgId } = payload;
      const body = {
        orgId: payload.orgId,
        prodIds: payload.products,
        domainName: payload.domainName,
        commission: payload.commission,
      };
      try {
        const response = await axios.post(
          `/accounts/v1/landing/org/${orgId}/products`,
          body,
        );
        notification('success', 'Successfully saved data');
        return response;
      } catch (error) {
        notification('warning', error);
      }
    },
    saveMedOrgDetailsDomain: async ({ commit }, { subDomain, orgId, domainId }) => {
      try {
        // THIS IS POST METHOD -> PARTIALLY COMMENTED
        // const { data } = await axios.post(`/accounts/v1/org/${orgId}/medOrgDetails`, {
        //   subDomain,
        // });
        const response = await axios.put(`/accounts/v1/org/${orgId}/medOrgDetails/${domainId}`, {
          subDomain,
        });
        notification('success', 'Domain name successfully saved!');
        if (response) {
          const { data } = response;
          commit('setCurrentDomain', data);
          return data;
        }
      } catch (error) {
        notification('warning', error.response.data.message);
        commit('setCurrentDomain', null);
        return null;
      }
    },
    fetchOrgDomain: async ({ commit }, { orgId }) => {
      try {
        const response = await axios.get(`/accounts/v1/org/${orgId}/domain`);
        if (response) {
          const { data } = response;
          commit('setCurrentDomain', data);
          return data;
        }
      } catch (error) {
        commit('setCurrentDomain', null);
        return null;
      }
    },
    updateOrgPracticePrice: async (_, payload) => {
      try {
        const response = await axios.post(
          '/products/v1/org/practicePrice',
          payload,
        );
        notification('success', 'Product successfully updated');
        if (response) {
          const { data } = response;
          return data;
        }
      } catch (error) {
        notification('warning', error);
      }
    },
    // FETCH PRODUCTS
    fetchAllStoreProducts: async (_, { orgId, params }) => {
      try {
        const response = await axios.get(
          `products/v1/marketplace/org/${orgId}/products${params}`,
        );

        return response.data;
      } catch (error) {
        notification('warning', error);
      }
    },
  },
};
