import Vue from "vue";
import { notification } from "ant-design-vue";
import axios from "../lib/axios";
import globalUtil from "@/utils/global";

export default {
  state: {
    authData: {},
    myProfileStep: false,
    searchQuery: "",
    queryResults: [],
    cart: [],
    selectedAddress: {},
    currentProduct: {},
    categoryProducts: {},
    carouselProducts: {},
    currentParentCategory: {},
    allCategories: [],
    featuredProducts: [],
    checkout: {
      purchase: {},
      availableShippingRates: {
        // fullfillmentId: array of rates
      },
      selectedShippingRates: [
        // { fulfillmentId, shippingRateId, rate(used for subtotal calculation) }
      ],
      selectedRegistrations: [
        // { fulfillmentId, registrationId }
      ],
      selectedPaymentMethodId: "",
      selectedPaymentMetodTypeId: 0,
      receipt: {},
    },
    emptyCart: false,
    recentViewResults: [],
    bestSellerResults: [],
    pageLength: 25,
    page: 1,
    recentViewPage: 1,
    similarPage: 1,
    bestSellerPage: 1,
    checkoutStep: false,
    availableShippingRatesObject: {},
    address: {},
    callbackUrl: {},
  },

  getters: {
    getRecentViewResults: (state) => {
      return state.recentViewResults;
    },
    getMyProfileStep: (state) => {
      return state.myProfileStep;
    },
    getBestSellerResults: (state) => {
      return state.bestSellerResults;
    },
    getEmptyCart: (state) => {
      return state.emptyCart;
    },
    getCheckoutStep: (state) => {
      return state.checkoutStep;
    },
    getCurrentProduct: (state) => {
      return state.currentProduct;
    },
    getProductQueryResults: (state) => {
      return state.queryResults.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        if (a.name === b.name) {
          if (a.vendor.name < b.vendor.name) {
            return -1;
          }
          if (a.vendor.name > b.vendor.name) {
            return 1;
          }
        }
        return 0;
      });
    },
    getCategoryProducts: (state) => {
      return state.categoryProducts;
    },
    getCategoryImages: (state) => {
      return state.categoryHeroImages;
    },
    getAllCategories: (state) => {
      return state.allCategories;
    },
    getCarouselProducts: (state) => {
      return state.carouselProducts;
    },
    getCartDisparities: (state) => {
      return state.checkout.purchase.disparities;
    },
    getCartItems: (state) => {
      return state.cart.sort((a, b) => {
        if (a.vendorName < b.vendorName) {
          return -1;
        }
        if (a.vendorName > b.vendorName) {
          return 1;
        }
        if (a.vendorName === b.vendorName) {
          if (a.productName < b.productName) {
            return -1;
          }
          if (a.productName > b.productName) {
            return 1;
          }
        }
        return 0;
      });
    },
    getCurrentPurchase: (state) => {
      return state.checkout.purchase;
    },
    getCurrentPurchaseFulfillments: (state) => {
      return state.checkout.purchase.fulfillments.sort((a, b) => {
        if (a.vendorName < b.vendorName) {
          return -1;
        }
        if (a.vnedorName > b.vendorName) {
          return 1;
        }
        return 0;
      });
    },
    getAvailableShippingRates: (state) => {
      return state.checkout.availableShippingRates;
    },
    getAvailableShippingRatesObject: (state) => {
      return state.availableShippingRatesObject;
    },
    getSelectedShippingRates: (state) => {
      return state.checkout.selectedShippingRates;
    },
    getSelectedRegistrations: (state) => {
      return state.checkout.selectedRegistrations;
    },
    getSelectedPaymentMethodId: (state) => {
      return state.checkout.selectedPaymentMethodId;
    },
    getPurchaseReceipt: (state) => {
      return state.checkout.receipt;
    },
    getProductPageLength(state) {
      return state.pageLength;
    },
    getRecentViewPage(state) {
      return state.recentViewPage;
    },
    getBestSellerPage(state) {
      return state.bestSellerPage;
    },
    getSimilarPage(state) {
      return state.similarPage;
    },
    getRecentViewPageResults(state, getters) {
      const page = getters.getRecentViewPage;
      const results = getters.getRecentViewResults;
      const pageLength = getters.getProductPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getBestSellerPageResults(state, getters) {
      const page = getters.getBestSellerPage;
      const results = getters.getBestSellerResults;
      const pageLength = getters.getProductPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getNumberOfRecentViewPages(state, getters) {
      const pages = Math.ceil(getters.getRecentViewResults.length / getters.getProductPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfBestSellerPages(state, getters) {
      const pages = Math.ceil(getters.getBestSellerResults.length / getters.getProductPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getAddressById(state) {
      return state.address;
    },
    getCallbackUrl(state) {
      return state.callbackUrl;
    },
    getGeneratedKey(state) {
      return state.authData.apiKey;
    },
  },

  mutations: {
    setRecentViewResults(state, payload) {
      state.recentViewResults = payload;
    },
    setBestSellerResults(state, payload) {
      state.bestSellerResults = payload;
    },
    async setCurrentPurchase(state, purchase) {
      if (purchase.disparities.length > 0) {
        await new Promise((resolve) => {
          const uniqueProducts = [];
          const uniqueProductIds = new Set();
          for (const item of purchase.disparities) {
            if (!uniqueProductIds.has(item.cartItem.productId)) {
              uniqueProductIds.add(item.cartItem.productId);
              uniqueProducts.push(item);
            }
          }
          purchase.disparities = uniqueProducts;

          // Resolve the Promise to indicate that the loop is done
          resolve();
        });
      }
      state.checkout.purchase = purchase;
    },
    setCurrentProductData(state, product) {
      state.currentProduct = product;
    },
    setCarouselProducts(state, payload) {
      /*
      Use Vue.set to handle property addition/deletion:
      https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      */
      Vue.set(state.carouselProducts, payload.categoryId, payload.products);
    },
    setCategoryProducts(state, products) {
      state.categoryProducts = products;
    },
    setCurrentParentCategory(state, category) {
      state.currentParentCategory = category;
    },
    setCategories(state, data) {
      state.allCategories = data;
    },
    setFeatured(state, data) {
      state.featuredProducts = data;
    },
    setQueryResults(state, data) {
      state.queryResults = data;
    },
    setCartItems(state, cart) {
      state.cart = cart;
    },
    emptyCart(state) {
      state.cart = [];
    },
    setSelectedAddress(state, address) {
      state.selectedAddress = address;
    },
    removeCartItem(state, legacyProductId) {
      state.cart = state.cart.filter((cartItem) => {
        return cartItem.legacyProductId !== legacyProductId;
      });
    },
    setAvailableShippingRates(state, payload) {
      const { fulfillmentId, availableRates } = payload;
      /*
      Use Vue.set to handle property addition/deletion:
      https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      */
      Vue.set(state.checkout.availableShippingRates, fulfillmentId, availableRates);
      state.availableShippingRatesObject = payload.availableRates;
    },
    setSelectedShippingRate(state, payload) {
      const selectedRateAlreadyExists = state.checkout.selectedShippingRates.some((rate) => {
        return rate.fulfillmentId === payload.fulfillmentId;
      });
      if (selectedRateAlreadyExists) {
        state.checkout.selectedShippingRates = state.checkout.selectedShippingRates.map((currentRate) => {
          if (currentRate.fulfillmentId === payload.fulfillmentId) {
            return payload;
          }
          return currentRate;
        });
      } else state.checkout.selectedShippingRates.push(payload);
    },
    setSelectedRegistration(state, payload) {
      const registrationAlreadyExists = state.checkout.selectedRegistrations.some((reg) => {
        return reg.fulfillmentId === payload.fulfillmentId;
      });
      if (registrationAlreadyExists) {
        state.checkout.selectedRegistrations = state.checkout.selectedRegistrations.map((currentReg) => {
          if (currentReg.fulfillmentId === payload.fulfillmentId) {
            return payload;
          }
          return currentReg;
        });
      } else state.checkout.selectedRegistrations.push(payload);
    },
    setSelectedPaymentId(state, payload) {
      state.checkout.selectedPaymentMethodId = payload;
    },
    setPurchaseReceipt(state, payload) {
      state.checkout.receipt = payload;
    },
    setEmptyCart(state, payload) {
      state.emptyCart = payload;
      state.checkout.selectedShippingRates = [];
      state.checkout.purchase = {};
    },
    setCheckoutStep(state, payload) {
      state.checkoutStep = payload;
    },
    setMyProfileStep(state, payload) {
      state.myProfileStep = payload;
    },
    resetCheckout(state) {
      state.checkout.purchase = {};
      state.checkout.availableShippingRates = {};
      state.checkout.selectedShippingRates = [];
      state.checkout.selectedRegistrations = [];
      state.checkout.selectedPaymentMethodId = "";
      state.checkout.selectedPaymentMetodTypeId = 0;
      state.checkout.receipt = {};
    },

    setSimilarPage(state, payload) {
      state.similarPage = payload;
    },

    setBesrSellerPage(state, payload) {
      state.bestSellerPage = payload;
    },

    setRecentViewPage(state, payload) {
      state.recentViewPage = payload;
    },

    setEmptyShippingRate(state, payload) {
      state.checkout.selectedShippingRates = payload;
    },
    setAddressById(state, payload) {
      state.address = payload;
    },
    setCallbackUrl(state, payload) {
      state.callbackUrl = payload;
    },
    setGeneratedKey(state, payload) {
      state.authData = payload;
    },
    deleteProductOnDisparityList(state, payload) {
      state.checkout.purchase.disparities.splice(payload, 1);
    },
  },

  actions: {
    updateFulfillmentByPatient: async ({ commit }, payload) => {
      const { locationId, purchaseId, orgId, patientId } = payload;
      const body = {};
      const response = await axios.put(
        `/orders/v1/buyer/${locationId}/purchase/${purchaseId}/org/${orgId}/patUser/${patientId}`,
        body
      );
    },
    updateActivationWithShipping: async ({ commit, getters }, payload) => {
      const shippingWay = payload.shippingType === "10" ? "ONE_WAY_SHIPPING" : "TWO_WAY_SHIPPING";

      const body = {
        activationCode: payload.activationCode,
        shippingType: shippingWay,
        fulfillmentId: getters.getCurrentPurchase.fulfillments[0].fulfillmentId,
        locationId: payload.locationId,
        patientId: payload.patientId,
        doctorId: payload.userId,
        patientAddr: payload.isPatientAdd,
        activateKitShippType: payload.shippingType,
        collectionKitPrice: payload.collectionKitPrice,
      };
      const { fulfillmentId } = getters.getCurrentPurchase.fulfillments[0];
      const response = await axios.post(`/orders/v1/fulfillment/${fulfillmentId}/activateKit`, body);
    },
    sendXMLRequestToLab: async ({ commit }, payload) => {
      const body = {
        locationId: payload.locationId,
        productId: payload.productId,
        patientId: payload.patientId,
        quantity: 1,
        activationCode: payload.activationCode,
        npiNumberId: payload.npiNumberId,
      };
      const response = await axios.post("/accounts/v1/lab", body);
    },
    updateFulfillmentForKitActivation: async ({ commit, getters }, locationId) => {
      const body = {};
      const { fulfillmentId } = getters.getCurrentPurchase.fulfillments[0];
      const buyerId = locationId;
      const response = await axios.put(`/orders/v1/buyer/${buyerId}/fulfillment/${fulfillmentId}`, body);
    },
    nextSimilarPage({ getters, commit }) {
      commit("setSimilarPage", getters.getSimilarPage + 1);
    },
    prevSimilarPage({ getters, commit }) {
      commit("setSimilarPage", getters.getSimilarPage - 1);
    },
    commitSimilarPage({ commit }, payload) {
      commit("setSimilarPage", payload);
    },

    nextBesrSellerPage({ getters, commit }) {
      commit("setBesrSellerPage", getters.getBestSellerPage + 1);
    },
    prevBesrSellerPage({ getters, commit }) {
      commit("setBesrSellerPage", getters.getBestSellerPage - 1);
    },
    commitBesrSellerPage({ commit }, payload) {
      commit("setBesrSellerPage", payload);
    },

    nextRecentViewPage({ getters, commit }) {
      commit("setRecentViewPage", getters.getRecentViewPage + 1);
    },
    prevRecentViewPage({ getters, commit }) {
      commit("setRecentViewPage", getters.getRecentViewPage - 1);
    },
    commitRecentViewPage({ commit }, payload) {
      commit("setRecentViewPage", payload);
    },

    fetchRecentlyViewProducts: async ({ commit, getters }) => {
      const response = await axios.get("/products/v1/market/recent");
      commit("setRecentViewResults", response.data);
    },
    fetchBestSellerProducts: async ({ commit, getters }) => {
      // commit('setLoadingStatus', true);
      const response = await axios.get("/products/v1/market/bsearch?q=se&orgId=2b276f4c-5afc-40ed-927d-0b91d7eb4d88");
      commit("setBestSellerResults", response.data);
    },
    // Begin Checkout Flow
    setEmptyCart: async ({ commit }, emptyCart) => {
      commit("setEmptyCart", emptyCart);
    },
    commitEmptyCart: async ({ commit }) => {
      commit("emptyCart");
    },
    setCheckoutStep: async ({ commit }, step) => {
      commit("setCheckoutStep", step);
    },
    setMyProfileStep: async ({ commit }, step) => {
      commit("setMyProfileStep", step);
    },
    commitResetCheckout: async ({ commit }) => {
      commit("resetCheckout");
    },
    fetchActiveCart: async ({ commit }, locationId) => {
      const response = await axios.get(`/orders/v1/cart/${locationId}`);
      commit("setCartItems", response.data);
    },
    createPurchaseForKit: async ({ commit }, payload) => {
      const body = {
        patientId: payload.patientId,
        isMarketplacePurchase: false,
        openOrderId: payload.openOrderId,
      };
      const { locationId } = payload;
      const response = await axios.post(`/orders/v1/buyer/${locationId}/purchase`, body);
      commit("setCurrentPurchase", response.data);
    },

    createPurchase: async ({ commit }, locationId) => {
      const body = {
        isMarketplacePurchase: true,
      };
      const response = await axios.post(`/orders/v1/buyer/${locationId}/purchase`, body);
      commit("setCurrentPurchase", response.data);
    },

    fetchAvailableShippingRates: async ({ commit }, body) => {
      try {
        const response = await axios.post("/shipping/v1/shipment", body);
        if (response) {
          const { data } = response;
          commit("setAvailableShippingRates", data);
          return data;
        }
      } catch (error) {
        notification.warning({
          description: error.response.data.error,
        });
        return null;
      }
    },

    commitSelectedShippingRate: async ({ commit }, shippingRate) => {
      commit("setSelectedShippingRate", shippingRate);
    },
    commitEmptyShippingRate: async ({ commit }, data) => {
      commit("setEmptyShippingRate", data.payload);
    },
    addShippingRatesToPurchase: async ({ commit }, payload) => {
      const { locationId, purchaseId, shippingRates } = payload;
      const response = await axios.post(
        `/orders/v1/buyer/${locationId}/purchase/${purchaseId}/shipping`,
        // `/orders/v1/buyer/${locationId}/purchase/1215000000705/shipping`,
        shippingRates
      );
      commit("setCurrentPurchase", response.data);
    },

    commitSelectedRegistration: async ({ commit }, registration) => {
      commit("setSelectedRegistration", registration);
    },

    addRegistrationsToPurchase: async ({ commit }, payload) => {
      const { locationId, purchaseId, registrations } = payload;
      const response = await axios.post(
        `/orders/v1/buyer/${locationId}/purchase/${purchaseId}/registration`,
        registrations
      );
      commit("setCurrentPurchase", response.data);
    },

    // eslint-disable-next-line no-empty-pattern
    subscribeToBackorderNotification: async ({}, payload) => {
      const { productId, userId } = payload;
      const body = {
        productId,
        userId,
      };
      return axios.post("backorder/v1/expectant", body);
    },

    commitSelectedPaymentMethodId: async ({ commit }, paymentMethodId) => {
      commit("setSelectedPaymentId", paymentMethodId);
    },

    addLoyaltyToPurchase: async ({ commit }, payload) => {
      const { locationId, purchaseId } = payload;
      const amount = { amount: payload.amount };
      const response = await axios.put(`/orders/v1/buyer/${locationId}/purchase/${purchaseId}/loyalty`, amount);
      commit("setCurrentPurchase", response.data);
    },

    addPaymentToPurchase: async ({ commit }, payload) => {
      const { locationId, purchaseId, payment } = payload;
      const response = await axios.post(`/orders/v1/buyer/${locationId}/purchase/${purchaseId}/payment`, payment);
      commit("setCurrentPurchase", response.data);
    },
    completePurchaseForPatient: async ({ commit }, payload) => {
      const { locationId, purchaseId, practiceId } = payload;
      const response = await axios.post(
        `/orders/v1/buyer/${locationId}/purchase/${purchaseId}/finalize/${practiceId}`,
        {}
      );
      commit("setPurchaseReceipt", response.data);
    },

    completeLabOrder: async ({ commit }, payload) => {
      const { locationId, purchaseId, patientId, openOrderId } = payload;
      const response = await axios.post(
        `orders/v1/buyer/${locationId}/purchase/${purchaseId}/patient/${patientId}/openOrder/${openOrderId}/finalizeLabOrder`,
        {}
      );
      commit("setPurchaseReceipt", response.data);
    },

    completePurchase: async ({ commit }, payload) => {
      const { locationId, purchaseId } = payload;
      try {
        const body = {
          paymentWithoutCc: payload.termsFlag,
        };
        const response = await axios.post(`/orders/v1/buyer/${locationId}/purchase/${purchaseId}/finalize`, body);
        commit("setPurchaseReceipt", response.data);
      } catch (error) {
        notification.warning({
          description: error.response.data.error,
        });
      }
    },

    // Complete checkout flow

    fetchProductById: async ({ commit }, productId) => {
      const response = await axios.get(`/products/v1/market/product/${productId}`);
      commit("setCurrentProductData", response.data);
    },

    fetchVendorProductById: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { productId } = payload;

      const response = await axios.get(`/products/v1/vendor/${vendorId}/product/${productId}`);
      commit("setCurrentProductData", response.data);
    },

    fetchProductBySlug: async ({ commit }, payload) => {
      const { vin } = payload;
      const { slug } = payload;

      const response = await axios.get(`/products/v1/market/product/${vin}/${slug}`);
      commit("setCurrentProductData", response.data);
    },

    fetchProductByCategory: async ({ commit }, categoryId) => {
      const response = await axios.get(`/products/v1/market/category/${categoryId}`);
      commit("setQueryResults", response.data);
      commit("setCategoryProducts", response.data);
      commit("setCarouselProducts", { categoryId, products: response.data });
    },

    fetchProductsByVendorId: async ({ commit }, vendorId) => {
      const response = await axios.get(`/products/v1/market/vendor/${vendorId}`);
      commit("setVendorProducts", response.data);
    },

    fetchAllCategories: async ({ commit }) => {
      const response = await axios.get("/products/v1/market/category");
      commit("setCategories", response.data);
    },

    fetchQueryResults: async ({ commit }, query) => {
      const response = await axios.get(`/products/v1/market/search?q=${query}`);
      commit("setQueryResults", response.data);
    },

    clearQueryResults: async ({ commit }) => {
      commit("setQueryResults", []);
    },

    fetchQueryResultsInCategory: async ({ commit }, payload) => {
      const { query, categoryId } = payload;
      const response = await axios.get(`/products/v1/market/search?q=${query}&c=${categoryId}`);
      commit("setQueryResults", response.data);
    },

    fetchAllSavedCarts: async ({ commit }, buyerId) => {
      const response = await axios.get(`/orders/v1/cart/${buyerId}/saved`);
    },

    fetchSavedCart: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { cartId } = payload;

      const response = await axios.get(`/orders/v1/cart/${buyerId}/saved/${cartId}`);
    },

    addItemToCart: async ({ commit, rootGetters, dispatch }, payload) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const { buyerId } = payload;
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: payload.qty,
        };

        const response = await axios.post(`/orders/v1/cart/${buyerId}/item`, body);
        commit("setCartItems", response.data);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },

    saveActiveCart: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const body = {
        name: payload.name,
      };

      const response = await axios.post(`/orders/v1/cart/${buyerId}`, body);
    },

    activateSavedCart: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { cartId } = payload;
      const body = {};

      const response = await axios.post(`/orders/v1/cart/${buyerId}/saved/${cartId}`, body);
    },

    updateItemQty: async ({ commit }, payload) => {
      // const { locationId, productId, qty } = payload;
      const { locationId } = payload;
      const { productId } = payload;
      const { qty } = payload;
      const body = {
        qty,
      };

      const response = await axios.put(`/orders/v1/cart/${locationId}/item/${productId}`, body);
      commit("setCartItems", response.data);
    },

    renameSavedCart: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { cartId } = payload;
      const body = {
        name: payload.name,
      };

      const response = await axios.post(`/orders/v1/cart/${buyerId}/saved/${cartId}`, body);
    },

    emptyActiveCart: async ({ commit }, buyerId) => {
      const response = await axios.delete(`/orders/v1/cart/${buyerId}`);
      commit("emptyCart");
    },

    removeItemFromCart: async ({ commit }, payload) => {
      const { locationId, productId } = payload;
      try {
        const response = await axios.delete(`/orders/v1/cart/${locationId}/item/${productId}`);
        commit("setCartItems", response.data);
        return response.data;
      } catch (error) {
        notification.warning({
          description: error.response.data.error,
        });
        return null;
      }
    },

    deleteSavedCart: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { cartId } = payload;

      const response = await axios.delete(`/orders/v1/cart/${buyerId}/saved/${cartId}`);
    },

    fetchAddressById: async ({ commit }, addressId) => {
      const response = await axios.get(`/shipping/v1/address/${addressId}`);
      commit("setAddressById", response.data);
    },

    fetchCallbackUrl: async ({ commit }, orgId) => {
      const response = await axios.get(`/orders/v1/callbackurl/${orgId}`);
      commit("setCallbackUrl", response.data);
    },

    postCallbackUrl: async ({ commit }, payload) => {
      const response = await axios.post("/orders/v1/callbackurl", payload);
      commit("setCallbackUrl", response.data);
    },

    generateApiKey: async ({ commit }, payload) => {
      const { orgId, locationId } = payload;
      const response = await axios.post(`/orders/v1/org/${orgId}/location/${locationId}/apiKey`, payload);
      commit("setGeneratedKey", response.data);
    },
    fetchApiAuthKeyValue: async ({ commit }, payload) => {
      const { orgId, locationId } = payload;
      const response = await axios.get(`/orders/v1/org/${orgId}/location/${locationId}/apiKey`);
      commit("setGeneratedKey", response.data);
    },
  },
};
