import moment from "moment";
import { thisDay, nextDay } from "@/plugins/moment";

const getPatientDetails = (state) => {
  return state.patientDetails;
};

const getPatientLabAppointments = (state) => {
  return state.patientLabAppointments;
};

const getCurrentLocationAppointments = (state) => {
  return state.appointments;
};

const getLocationUpcomingAppointments = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return aptDate > today;
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsToday = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return (
        aptDate.getDate() === today.getDate() &&
        aptDate.getMonth() === today.getMonth() &&
        aptDate.getFullYear() === today.getFullYear()
      );
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getAppointmentsTomorrow = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return thisDay(aptDate) === nextDay(today);
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsThisMonth = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return aptDate.getMonth() === today.getMonth() && aptDate.getFullYear() === today.getFullYear();
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getAppointmentsNextMonths = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return aptDate.getMonth() === today.getMonth() + 1 && aptDate.getFullYear() === today.getFullYear();
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsLastMonth = (state) => {
  const today = new Date();
  return state.appointments
    .filter((appointment) => {
      const aptDate = new Date(appointment.date);
      return aptDate.getMonth() === today.getMonth() - 1 && aptDate.getFullYear() === today.getFullYear();
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getCustomAppointments = (state) => {
  return (start, end) => {
    return state.appointments
      .filter((appointment) => {
        const startDate = moment(start);
        const endDate = moment(end);
        return moment(appointment.date).isSameOrAfter(startDate, "day") && moment(appointment.date).isSameOrBefore(endDate, "day");
      })
      .sort((a, b) => {
        const dateOne = new Date(a.date);
        const dateTwo = new Date(b.date);
        if (dateOne > dateTwo) {
          return 1;
        }
        return -1;
      });
  };
};

const getCurrentPatientAppointments = (state) => {
  return state.currentPatientAppointments;
};

const getCurrentPatientDosingAppointments = (state) => {
  return state.currentPatientAppointments
    ? state.currentPatientAppointments.filter((appointment) => appointment.type === "dosing")
    : [];
};

const getCurrentPatientConsultationAppointments = (state) => {
  return state.currentPatientAppointments.filter((appointment) => appointment.type === "consultation");
};

const getPatientRedirectBackTo = (state) => {
  return state.patientRedirectBackTo;
};

export default {
  getPatientLabAppointments,
  /**
   * APPOINTMENTS GETTER
   */
  getCurrentLocationAppointments,
  getLocationAppointmentsThisMonth,
  getAppointmentsNextMonths,
  getLocationAppointmentsLastMonth,
  getLocationUpcomingAppointments,
  getLocationAppointmentsToday,
  getAppointmentsTomorrow,
  getCustomAppointments,
  getPatientDetails,
  getCurrentPatientAppointments,
  getCurrentPatientDosingAppointments,
  getCurrentPatientConsultationAppointments,
  getPatientRedirectBackTo,
};
