import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";

const add503aProductToCart = async (_, { locationId, patientId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/cart/location/${locationId}/patient/${patientId}/503a-item`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Added to Cart",
  };
  const response = await proxy.execute(query, payload, notificationParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatientDraftCartTotal = async (_, { locationId, patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/cart/location/${locationId}/patient/${patientId}/503a-item/total`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchDraftPrescriptionCart = async (_, { locationId, patientId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/buyer/${locationId}/purchase/503a`;
  const payload = {
    patientId,
  };
  const response = await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: false,
      successNotificationMsg: "",
    },
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const delete503aProductToCart = async (_, { locationId, lineItemId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `orders/v1/lineitem/${lineItemId}`;
  const response = await proxy.execute(query, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully deleted product!",
    },
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const insertPrescriptionProductNotes = async (_, { lineItemId, payload }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `orders/v1/fulfillment/line-item/${lineItemId}/notes`;
  const response = await proxy.execute(query, payload, "Successfully Added Note!");
  if (response) {
    const { data } = response;
    return data;
  }
};

const placeOrder = async (_, { locationId, purchaseId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/buyer/${locationId}/purchase/${purchaseId}/503a`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Placed an Order.",
  };
  const response = await proxy.execute(query, payload, notificationParams);
  if (response && response.data) {
    const { data } = response;
    return data;
  }
};

// USE THIS CODE AND DEPRICATE ALL METHODS THAT HAS THE SAME URL
const addProductToMarketplaceCart = async ({ commit }, { locationId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/cart/${locationId}/item`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Added to Cart",
  };
  const response = await proxy.execute(query, payload, notificationParams);
  if (response) {
    const { data } = response;
    // setCartItems is in MP.js refactor this when have time
    commit("setCartItems", response.data, { root: true });
    return data;
  } else {
    return null;
  }
};

// USE THIS CODE AND DEPRICATE ALL METHODS THAT HAS THE SAME URL
const fetchPreOrderCart = async ({ commit }, { orgId, locationId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/org/${orgId}/location/${locationId}/pre-order?hasPreorder=true`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setPreOrdersCartItems", response.data);
    return data;
  } else {
    return null;
  }
};

const checkLocationPreOrders = async (_, { orgId, locationId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/org/${orgId}/location/${locationId}/draftPreOrders`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  } else {
    return false;
  }
};

const addPreOrderToCart = async (_, { orgId, locationId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/org/${orgId}/location/${locationId}/pre-order`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Added to Pre-Order Cart",
  };
  const response = await proxy.execute(query, payload, notificationParams);
  if (response) {
    const { data } = response;
    return data;
  }
};
const deleteProductInPreOrderCart = async (_, { orgId, locationId, productId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `orders/v1/org/${orgId}/location/${locationId}/pre-order/${productId}`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Deleted product in the Cart",
  };
  const response = await proxy.execute(query, { notificationParams });
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  add503aProductToCart,
  fetchDraftPrescriptionCart,
  delete503aProductToCart,
  insertPrescriptionProductNotes,
  placeOrder,
  fetchPatientDraftCartTotal,
  addProductToMarketplaceCart,
  fetchPreOrderCart,
  checkLocationPreOrders,
  addPreOrderToCart,
  deleteProductInPreOrderCart,
};
