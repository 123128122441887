import axios from '@/lib/axios';

export default {
  namespaced: true,
  state: {
    patientResults: [],
    results: [],
    page: 1,
    pageLength: 25,
  },

  getters: {
    getLabPatients: (state) => {
      return state.patientResults;
    },
    getNumberOfPatientsPages(state, getters) {
      const pages = Math.ceil(
        getters.getLabPatients.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getLabPatientsPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getLabPatients;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setLabPatients(state, payload) {
      state.patientResults = payload;
    },
  },

  actions: {
    // fetchLabPatients: async ({ commit }, locationId) => {
    //   const response = await axios.get(
    //     `/patient/v1/location/${locationId}/patient`,
    //   );
    //   commit('setLabPatients', response.data);
    // },
    nextPage({ getters, commit }) {
      commit('setPage', getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit('setPage', getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit('setPage', payload);
    },
  },
};
