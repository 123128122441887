export default {
  state: {
    offCanvasMenu: false,
    orgDashboardIndex: '',
    requireLocationDialog: false,
  },

  getters: {
    getOrgDashboardIndex: (state) => {
      return state.orgDashboardIndex;
    },
    getOffCanvasMenuStatus: (state) => {
      return state.offCanvasMenu;
    },
    getRequireLocationDialog: (state) => {
      return state.requireLocationDialog;
    },
  },

  mutations: {
    setRequireLocationDialog(state, payload) {
      state.requireLocationDialog = payload;
    },
    toggleOffCanvasMenu(state, payload) {
      state.offCanvasMenu = payload.toggle;
    },
    setOrgDashboardIndex(state, payload) {
      state.orgDashboardIndex = payload;
    },
  },

  actions: {
    commitSetRequireLocationDialog: ({ commit }, payload) => {
      commit('setRequireLocationDialog', payload);
    },
    updateOrgDashboardIndex: ({ commit }, payload) => {
      commit('setOrgDashboardIndex', payload);
    },
  },
};
