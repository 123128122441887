import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import outreachState from "./state/outreach";

export default {
  namespaced: true,
  state: {
    ...outreachState,
    organizationDetails: null,
  },
  getters,
  mutations,
  actions,
};
