import axios from '@/lib/axios';
import notification from '@/utils/notifications';

export default {
  namespaced: true,
  state: {
    medcenterOrder: {},
    shippingRates: [],
    openProducts: [],
    openOrderDetails: {},
    activationKit: {},
    activationCode: '',
    orders: [],
    labPatients: [],
    labResults: [],
    results: [],
    page: 1,
    pageLength: 15,
    pendingLabResults: [],
    completedLabResults: [],
    currentOrderDetails: {
      buyer: {},
      estShippingRateId: '',
      items: [],
      subtotal: 0,
      total: 0,
      shipToAddress: {
        shippingAddress: '',
        shippingRegion: '',
        shippingCity: '',
        shippingPostCode: '',
      },
    },
    allMedcenterPatients: [
      {
        firstName: 'Patient',
        lastName: 'User',
        email: 'puser001@intellipel.com',
      },
    ],
    patientLabsInvoice: [],
    fulfillmentInfo: {},
    openLabOrders: [],
    activationInfo: {
      providerNPI: '',
      activationCode: '',
      shipping: '',
      collectionKitType: '',
      manufactureSku: '',
      isRequireShippingLabel: false,
    },
    patientId: '',
    locationId: '',
    universalOrder: {},
  },

  getters: {
    getUniversalOrderDetails: (state) => {
      return state.universalOrder;
    },
    getShippingAppliedRate: (state) => {
      return state.shippingRates;
    },
    getActivationKit: (state) => {
      return state.activationKit;
    },
    getPatientId: (state) => {
      return state.patientId;
    },
    getLocationId: (state) => {
      return state.locationId;
    },
    getActivationCode: (state) => {
      return state.activationCode;
    },
    getFulfillmentInfo: (state) => {
      return state.fulfillmentInfo;
    },
    getLocationOrderDetails: (state) => {
      return state.currentOrderDetails;
    },
    getCurrentOrderStatusHuman: (state) => {
      if (state.currentOrderDetails.status === 'Processing') {
        return 'Pending';
      }
      return state.currentOrderDetails.status;
    },
    getLabPatients: (state) => {
      return state.labPatients;
    },
    getAllPatientsWithDetails: (state) => {
      return state.allMedcenterPatients;
    },
    getLabResults: (state) => {
      return state.labResults;
    },
    getNumberOfPatientsPages(state, getters) {
      const pages = Math.ceil(
        getters.getLabPatients.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getLabPatientsPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getLabPatients;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getPendingLabResults(state) {
      return state.pendingLabResults;
    },
    getCompletedLabResults(state) {
      return state.completedLabResults;
    },
    getLocationOrders(state) {
      return state.orders.sort((a, b) => {
        const aDate = new Date(a.paidAt);
        const bDate = new Date(b.paidAt);
        return bDate - aDate;
      });
    },
    getTotalSales: (state) => {
      let total = 0;
      state.orders.forEach((order) => {
        total += order.total;
      });
      return total.toFixed(2);
    },
    getThisMonthsOrders: (state) => {
      const today = new Date();
      return state.orders.filter((order) => {
        const ordDate = new Date(order.paidAt);
        return (ordDate.getMonth() === today.getMonth()
          && ordDate.getFullYear() === today.getFullYear());
      }).sort((a, b) => {
        const dateOne = new Date(a.date);
        const dateTwo = new Date(b.date);
        if (dateOne > dateTwo) {
          return 1;
        }
        return -1;
      });
    },
    getLastMonthsOrders: (state) => {
      const today = new Date();
      return state.orders.filter((order) => {
        const aptDate = new Date(order.paidAt);
        return (aptDate.getMonth() === today.getMonth() - 1 && aptDate.getFullYear() === today.getFullYear());
      }).sort((a, b) => {
        const dateOne = new Date(a.date);
        const dateTwo = new Date(b.date);
        if (dateOne > dateTwo) {
          return 1;
        }
        return -1;
      });
    },
    getPatientLabsInvoice(state) {
      // return state.patientLabsInvoice;
      return state.patientLabsInvoice.sort((a, b) => {
        const dateOne = new Date(a.paidAt);
        const dateTwo = new Date(b.paidAt);
        if (dateOne < dateTwo) {
          return 1;
        }
        return -1;
      });
    },
    getOpenLabOrders(state) {
      return state.openLabOrders;
    },
    getOpenOrderDetails(state) {
      return state.openOrderDetails;
    },
    getOpenProducts(state) {
      return state.openProducts;
    },
    getActivationInfo(state) {
      return state.activationInfo;
    },
  },

  mutations: {
    setShippingAppliedRate(state, payload) {
      state.shippingRates = payload;
    },
    setOpenLabOrders(state, payload) {
      state.openLabOrders = payload;
    },
    setOpenOrderDetails(state, payload) {
      state.openOrderDetails = payload;
      state.activationInfo.activationCode = payload.barcode;
      state.activationInfo.providerNPI = payload.npiNumberId;
      state.activationInfo.shipping = payload.shippingType ? payload.shippingType.toString() : '';
      state.activationInfo.collectionKitType = payload.collectionKitType;
    },
    setLabPatients(state, payload) {
      state.labPatients = payload;
    },
    setLabResults(state, payload) {
      state.labResults = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setPendingLabResults(state, payload) {
      state.pendingLabResults = payload;
    },
    setCompletedLabResults(state, payload) {
      state.completedLabResults = payload;
    },
    setAllPatientsWithDetails(state, payload) {
      state.allMedcenterPatients = payload;
    },
    setLocationOrders(state, payload) {
      state.orders = payload;
    },
    setLocationOrderDetails(state, payload) {
      state.currentOrderDetails = payload;
    },
    setActivationCode(state, payload) {
      state.activationCode = payload.activationCode;
    },
    setFulfillmentInfo(state, payload) {
      state.fulfillmentInfo = payload;
    },
    setPatientLabsInvoice(state, payload) {
      state.patientLabsInvoice = payload;
    },
    setActivatedKit(state, payload) {
      state.activationKit = payload;
    },
    setPatientId(state, payload) {
      state.patientId = payload.patientId;
    },
    setLocationId(state, payload) {
      state.locationId = payload.locationId;
    },
    setOpenProducts(state, payload) {
      state.openProducts = payload;
    },
    setActivationInfo(state, payload) {
      state.activationInfo = payload;
    },
    setUniversalOrderDetails(state, payload) {
      state.universalOrder = payload;
    },
  },

  actions: {
    saveMedcenterOrder: async ({ commit }, payload) => {
      const response = await axios.post('/orders/v1/marketplace/order/save', payload);
      commit('setOpenOrderDetails', response.data);
    },
    fetchUniversalOrderDetails: async ({ commit }, activationCode) => {
      const response = await axios.get(`/orders/v1/activation/${activationCode}`);
      commit('setUniversalOrderDetails', response.data);
    },
    fetchShippingAppliedRate: async ({ commit }, fulfillmentId) => {
      const response = await axios.get(`/orders/v1/fulfillment/${fulfillmentId}/medcenterShipp`);
      commit('setShippingAppliedRate', response.data);
    },
    resetActivationInfo({ commit }) {
      const payload = {
        providerNPI: '',
        activationCode: '',
        shipping: '',
        collectionKitType: '',
      };
      commit('setActivationInfo', payload);
    },
    fetchOpenProducts: async ({ commit }, payload) => {
      const { openOrderId, patientId, locationId } = payload;
      const response = await axios.get(`/orders/v1/buyer/${locationId}/patient/${patientId}/openOrder/${openOrderId}/openItems`);
      commit('setOpenProducts', response.data);
    },
    commitActivationInfo({ commit }, payload) {
      commit('setActivationInfo', payload);
    },
    saveOpenOrder: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const body = {
        shippingType: payload.shippingType,
        barcode: payload.activationCode,
        npiNumberId: payload.npiNumberId,
        patientId: payload.patientId,
        collectionKitType: payload.collectionKitType,
      };
      const response = await axios.post(`/orders/v1/buyer/${locationId}/patient/${patientId}/labOrder`, body);
      commit('setOpenOrderDetails', response.data);
    },
    cancelOpenOder: async ({ commit }, openOrderId) => {
      const response = await axios.post(`/orders/v1/labOrder/${openOrderId}`);
      commit('setOpenOrderDetails', response.data);
    },
    fetchOpenOrderDetails: async ({ commit }, payload) => {
      const { openOrderId, patientId } = payload;
      const response = await axios.get(`/orders/v1/patient/${patientId}/openOrder/${openOrderId}`);
      commit('setOpenOrderDetails', response.data);
    },
    fetchAllOpenLabOrders: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/orders/v1/buyer/${locationId}/patient/${patientId}/labOrder`);
      commit('setOpenLabOrders', response.data);
    },
    fetchActivatedKitByFulfillment: async ({ commit }, fulfillmentId) => {
      const response = await axios.get(
        `/orders/v1/activateKit/${fulfillmentId}`,
      );
      commit('setActivatedKit', response.data);
    },
    fetchLabBarcodeByFulfillment: async ({ commit }, fulfillmentId) => {
      const response = await axios.get(
        `/orders/v1/activateKit/${fulfillmentId}`,
      );
      commit('setActivationCode', response.data);
      commit('setFulfillmentInfo', response.data);
      commit('setPatientId', response.data);
      commit('setLocationId', response.data);
    },
    cancelCurrentVendorOrder: async ({ commit, getters }) => {
      const vendorId = getters.getVendorId;
      const { fulfillmentId } = getters.getVendorOrderDetails;
      const response = await axios.post(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/cancel`,
        {},
      );
      commit('setVendorOrderDetails', response.data);
    },
    // Add
    createShippingLabels: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;
      const body = payload.packages; // where packages is an array of package dim objects

      const response = await axios.post(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/parcel`,
        body,
      );

      commit('setVendorOrderDetails', response.data);
    },
    createCustomTrackers: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;
      const body = payload.trackers; // where trackers is an array of tracker objects

      const response = await axios.post(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/tracker`,
        body,
      );

      commit('setVendorOrderDetails', response.data);
    },
    fetchFulfillment: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;

      const response = await axios.get(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}`,
      );
      commit('setLocationOrderDetails', response.data);
    },
    fetchAllLocationFulfillments: async ({ commit }, buyerId) => {
      const response = await axios.get(
        `/orders/v1/buyer/${buyerId}/fulfillment`,
      );
      commit('setLocationOrders', response.data);
    },
    fetchAllPatientsWithDetails: async ({ commit }, orgId) => {
      const response = await axios.get(`/accounts/v1/patient/allPatients/${orgId}`);
      commit('setAllPatientsWithDetails', response.data);
    },
    approveOrder: async ({ dispatch, getters }, payload) => {
      const { purchaseId, buyerId, orgId } = payload;
      const body = {
      };
      await axios.post(
        `/orders/v1/buyer/${buyerId}/purchase/${purchaseId}/approve`,
        body,
      );
      await dispatch('fetchApprovalPatients', orgId);
      if (getters.getLabPatientsPageResults.length === 0) {
        dispatch('commitPage', 1);
      }
    },
    fetchCompletedLabResults: async ({ commit }, payload) => {
      const { orgId, locationId } = payload;
      const response = await axios.get(`/orders/v1/org/${orgId}/location/${locationId}/pCompleted`);
      commit('setCompletedLabResults', response.data);
    },
    fetchPendingLabResults: async ({ commit }, payload) => {
      const { orgId, locationId } = payload;
      const response = await axios.get(`/orders/v1/org/${orgId}/location/${locationId}/pResults`);
      commit('setPendingLabResults', response.data);
    },
    fetchAllPatients: async ({ commit }, orgId) => {
      const response = await axios.get(`/orders/v1/org/${orgId}/pApproval`);
      commit('setLabPatients', response.data);
    },
    fetchApprovalPatients: async ({ commit }, orgId) => {
      const response = await axios.get(`/orders/v1/org/${orgId}/pApproval/all`);
      commit('setLabPatients', response.data);
    },
    fetchMyNetworkOrder: async ({ commit }, orgId) => {
      const response = await axios.get(`/orders/v1/org/${orgId}/pApproval`);
      commit('setLabPatients', response.data);
    },
    fetchApprovalNetworkOrder: async ({ commit }, orgId) => {
      const response = await axios.get(`/orders/v1/org/${orgId}/pApproval/network`);
      commit('setLabPatients', response.data);
    },
    commitLabResults({ commit }, payload) {
      commit('setLabResults', payload);
    },
    commitAllPatients({ commit }, payload) {
      commit('setAllLabPatients', payload);
    },
    commitFilteredPatients({ commit }, payload) {
      commit('setLabPatients', payload);
    },
    nextPage({ getters, commit }) {
      commit('setPage', getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit('setPage', getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit('setPage', payload);
    },
    async fetchPatientLabsInvoice({ commit }, userId) {
      const response = await axios.get(`/orders/v1/fulfillment/patient/${userId}`);
      commit('setPatientLabsInvoice', response.data);
    },
    addComment: async ({ commit }, payload) => {
      const response = await axios.post(
        '/orders/v1/barcode', payload,
      );
      commit('setActivatedKit', response.data);
    },
    fetchComment: async ({ commit }, barcode) => {
      const response = await axios.get(
        `/orders/v1/comments/${barcode}`,
      );
      commit('setActivatedKit', response.data);
    },
    fetchActivationKit: async ({ commit }, barcode) => {
      const response = await axios.get(
        `/orders/v1/activatekit/${barcode}`,
      );
      commit('setActivatedKit', response.data);
    },
  },
};
