import vendorModule from "./vendorModule/state";
import marketplaceModuleState from "./marketplaceModule/state";
import marketModuleState from "./marketModule/state";
import lookupModuleState from "./lookupModule/state";

export default {
  ...vendorModule,
  ...marketplaceModuleState,
  ...marketModuleState,
  ...lookupModuleState,
};
