import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchAddressDetails = async (_, addressId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `shipping/v1/address/${addressId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};
export default {
  fetchAddressDetails,
};
