import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchProductLookups = async ({ commit }, lookupsType) => {
  const proxy = new GetSimpatraServiceProxy();
  const isDispenseType = lookupsType === "dispenseType";
  const isDispenseBase = lookupsType === "dispenseBase";
  let type = "";
  if (isDispenseType) {
    type = "dispense-type";
  } else if (isDispenseBase) {
    type = "product-base";
  } else {
    type = "product-uom";
  }
  const query = `products/v1/${type}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    if (isDispenseType) {
      commit("setDispenseType", data);
    } else if (isDispenseBase) {
      commit("setDispenseBase", data);
    } else {
      commit("setUOM", data);
    }
    return data;
  }
};

export default {
  fetchProductLookups,
};
