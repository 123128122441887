const getOrderHistory = (state) => {
  return state.orderHistory;
};

const getPracticeInvoice = (state) => {
  return state.practiceInvoices;
};

export default {
  getOrderHistory,
  getPracticeInvoice,
};
