import { some, isEmpty, get } from "lodash";
import axios from "@/lib/axios";
// eslint-disable-next-line import/no-cycle
import notification from "@/utils/notifications";
import apiLocations from "../lib/api-locations";

export default {
  state: {
    loggedIn: false,
    simpatraAdmin: false,
    subSuperAdmin: false,
    simpatraConsultant: false,
    orgOwner: false,
    orgAdmin: false,
    orgPermissions: [],
    locationPermissions: [],
    superAdminPermissions: null,
    locations: [],
    id: "",
    type: "",
    firstName: "",
    lastName: "",
    email: "",
    admin: false,
    userType: "",
    doctorDetails: null,
  },

  getters: {
    getCurrentUserType: (state) => {
      return state.userType;
    },
    getOrganizationOwner: (state) => {
      return state.orgOwner;
    },
    getAdminRole: (state) => {
      return state.orgAdmin;
    },
    getSimpatraAdmin: (state) => {
      return state.simpatraAdmin;
    },
    getCurrentUser: (state) => {
      return state;
    },
    // getUserType: (state) => {
    //   return state.type;
    // },
    getUserId: (state) => {
      return state.id;
    },
    getUserLoggedInStatus: (state) => {
      return state.loggedIn;
    },
    getUserAdminStatus: (state) => {
      return state.orgAdmin;
    },
    getUserLocations: (state) => {
      return state.locations;
    },
    getLocationProfilePermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocProfile";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationPaymentPermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocPayment";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationLicensePermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocLicenses";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationRegistrationPermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocRegistration";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getPatientPermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocPatient";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getSchedulingPermission: (state) => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find((permission) => {
          return permission.name === "LocScheduling";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getDoctorDetails(state) {
      return state.doctorDetails;
    },
    getSuperAdminPermission(state) {
      return state.superAdminPermissions;
    },
    currentLoggedIsLocationAdmin(state) {
      return some(state.locationPermissions, (permission) => {
        return permission.name === "LocAdmin" && permission.value === 2;
      });
    },
    isLoggedIn() {
      if (localStorage.getItem("exp") && localStorage.getItem("access_token")) {
        const tokenExpiration = Number(localStorage.getItem("exp"));

        const isExpired = tokenExpiration < new Date() / 1000;

        return !isExpired;
      }

      return false;
    },
  },

  mutations: {
    setUserInfo: (state, payload) => {
      if (payload.sim.simpatraAdmin) {
        state.simpatraAdmin = payload.sim.simpatraAdmin;
      }
      state.loggedIn = true;
      state.id = payload.sim.user.subject;
      state.type = payload.sim.user.userType;
      state.firstName = payload.sim.user.firstName;
      state.lastName = payload.sim.user.lastName;
      state.email = payload.sim.user.email;
      state.locations = payload.sim.details ? payload.sim.details.locations : [];
    },
    // setOrgUserDetails: (state, payload) => {
    //   // User Details
    //   state.orgOwner = payload.isOwner;
    //   state.orgAdmin = payload.isAdmin;
    //   state.orgPermissions = payload.orgPermissions;
    //   state.locationPermissions = payload.locationPermissions;
    //   state.locations = payload.locations;
    // },
    setOrgLocations: (state, payload) => {
      state.locations = payload.locations;
    },
    setAdminStatus(state, payload) {
      state.admin = payload;
    },
    setCurrentUserType(state, payload) {
      state.userType = payload.userType;
    },
    setDoctorDetails(state, payload) {
      state.doctorDetails = payload;
    },
    setSupperAdminPermission(state, payload) {
      state.superAdminPermissions = payload.superAdminPermissions ? payload.superAdminPermissions : [];
      state.subSuperAdmin = false;
      if (payload.superAdminPermissions) {
        payload.superAdminPermissions.forEach((element) => {
          if (element.value > 0) {
            state.subSuperAdmin = true;
          }
        });
      }
    },
    setCurrentUserTypes(state, payload) {
      state.type = payload;
    },
    updateUserLocation(state, payload) {
      const index = state.locations.findIndex((item) => {
        return item.id === payload.locationId;
      });
      if (index >= 0) {
        state.locations[index].isActive = payload.isActivate;
        state.locations[index].name = payload.name;
      } else {
        state.locations.push({
          isActive: true,
          id: payload.locationId,
          name: payload.name,
        });
      }
    },
    setUserLocations(state, payload) {
      state.locations = payload;
    },
  },

  // -----------------------------
  actions: {
    resetLoginDetails: ({ dispatch }) => {
      const payload = {
        sim: {
          loggedIn: false,
          simpatraAdmin: false,
          user: {
            subject: "",
            userType: "",
            firstName: "",
            lastName: "",
            email: "",
          },
        },
      };
      dispatch("setUserInfo", payload);
    },
    setUserInfo: ({ commit }, payload) => {
      commit("setUserInfo", payload);
    },
    fetchSubSupperAdminDetails: async ({ commit }, payload) => {
      try {
        const response = await axios.get(`accounts/v1/superadmin/permission/${payload}`);
        if (response) {
          const { data } = response;
          commit("setSupperAdminPermission", data);
        }
      } catch (error) {
        notification("warning", error.response.data.message);
      }
    },
    // setInitialOrgLocationDetails: async ({ commit }, payload) => {
    //   const isEmptyLocations =
    //     localStorage.getItem("currentLocation") !== null && localStorage.getItem("currentLocation") !== undefined;
    //   const orgLocations = payload.sim.details.locations;
    //   if (isEmptyLocations && !isEmpty(orgLocations)) {
    //     const activeLocations = orgLocations.filter((location) => location.isActive);
    //     const defaultLocation = activeLocations && activeLocations.length > 0 ? activeLocations[0] : orgLocations[0];
    //     const locationStatus = get(defaultLocation, "isActive", false) ? "active" : "deactivated";
    //     const locationId = get(defaultLocation, "id", null);
    //     localStorage.setItem("locationStatus", locationStatus);

    //     await commit("setLocationContext", {
    //       locationId,
    //       name: get(defaultLocation, "name", ""),
    //       locationStatus,
    //     });
    //   }
    // },
    setAdminStatus({ commit }, payload) {
      commit("setAdminStatus", payload);
    },
    setCurrentUserTypes({ commit }, payload) {
      commit("setCurrentUserTypes", payload);
    },
    commitCurrentUserType({ commit }, payload) {
      commit("setCurrentUserType", payload);
    },
    async fetchDoctorDetails({ commit }, payload) {
      const { doctorId } = payload;
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.access_token}`,
        },
      };
      const response = await axios.get(`accounts/v1/org/user/${doctorId}`, config);
      await commit("setDoctorDetails", response.data);
    },
    fetchUserLocations: async ({ commit }, payload) => {
      const result = await apiLocations.fetchUserLocations(payload);
      commit("setUserLocations", result);
    },
    clearLocalStore: async () => {
      localStorage.removeItem("exp");
      localStorage.removeItem("hydraState");
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
    },
  },
};
