import { get } from "lodash";

const setReportList = (state, payload) => {
  if (payload) {
    const data = get(payload, "data", []);
    state.reportList.paginationDetails.currentPage = get(payload, "currentPage", 1);
    state.reportList.paginationDetails.totalPages = get(payload, "totalPages", 0);
    state.reportList.paginationDetails.pageSize = get(payload, "size", 10);
    state.reportList.paginationDetails.totalData = get(payload, "totalData", 0);
    state.reportList.data = data;
  } else {
    state.reportList.data = [];
    state.reportList.paginationDetails = state.defaultPaginationDetails;
  }
};

const setReportFilters = (state, payload) => {
  if (payload) {
    state.reportList.filters = {
      ...state.reportList.filters,
      ...payload,
    };
  } else {
    state.reportList.filters = state.reportList.initialReportFilters;
  }
};

const updateReportList = (state, payload) => {
  const dataIndex = state.reportList.data.findIndex((item) => item.id === payload.data.id);
  if (dataIndex <= -1) return; // END METHOD if id doesn't exist
  if (payload.objName) {
    state.reportList.data[dataIndex][payload.objName][payload.prop] = payload.data.value;
  } else {
    state.reportList.data[dataIndex][payload.prop] = payload.data.value;
  }
};

export default {
  setReportList,
  setReportFilters,
  updateReportList,
};
