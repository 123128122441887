import { get, isNil } from "lodash";

const constructProductPayload = (payload, isBundle) => {
  const labTestId = get(payload, "labtestId", null);
  const isLabTestIdString = typeof payload.labtestId === "string";
  const labtestIdPayload = isLabTestIdString || isNil(payload.labtestId) ? labTestId : payload.labtestId.id;
  const subcategorySlug = get(payload, "subcategorySlug", "");
  const is503AProduct = subcategorySlug === "503A";
  const daysPayload = get(payload, "days") ? get(payload, "days") : 1;
  const days = is503AProduct ? daysPayload : null;

  return {
    id: get(payload, "id", ""),
    productName: get(payload, "name", ""),
    description: get(payload, "description", ""),
    sku: get(payload, "sku", ""),
    vendorSku: get(payload, "vendorSku", ""),
    mfgSku: get(payload, "mfgSku", ""),
    registrationRequired: get(payload, "registrationRequired", false),
    boundByState: get(payload, "boundByState", false),
    categorySlug: get(payload, "categorySlug", ""),
    subcategorySlug,
    weight: get(payload, "weight", ""),
    weightTypeId: get(payload, "weightTypeId", 1),
    cardVolume: get(payload, "cardVolume", ""),
    limited: get(payload, "limited", false),
    limitedQty: get(payload, "limitedQty", ""),
    minQtyForPurchase: get(payload, "minQtyForPurchase", 0),
    marketplaceStoreFront: get(payload, "marketplaceStoreFront", true),
    practiceStoreFront: get(payload, "practiceStoreFront", false),
    childProducts: get(payload, "childProducts", ""),
    isBundle: isBundle,
    rateType: get(payload, "rateType", "C"),
    labtestId: labtestIdPayload,
    labFee: get(payload, "labFee", ""),
    isVisibleMedcenter: get(payload, "isVisibleMedcenter", false),
    isVirtualProduct: get(payload, "isVirtualProduct", false),
    inStock: get(payload, "inStock", false),
    productShippingClassLevel: get(payload, "productShippingClassLevel", null),
    // 503A FIELDS
    drugType: get(payload, "drugType"),
    strength: get(payload, "strength"),
    showMfgSig: get(payload, "showMfgSig"),
    mfgSig: payload.mfgSig || [],
    size: get(payload, "size"),
    dispenseTypeId: get(payload, "dispenseTypeId"),
    productUomId: get(payload, "productUomId"),
    productBaseId: get(payload, "productBaseId"),
    refillLimit: get(payload, "refillLimit"),
    days,
    isPreorder: get(payload, "isPreorder"),
  };
};

const initProductData = (payload, isBundle) => {
  const subcategorySlug = get(payload, "subcategorySlug", "");
  const is503AProduct = subcategorySlug === "503A";
  const days = is503AProduct ? get(payload, "days", 1) : null;
  const refillLimit = is503AProduct ? get(payload, "refillLimit", 0) : null;
  const mfgSig = is503AProduct? payload.mfgSig || [] : null;
  return {
    id: get(payload, "id", ""),
    name: get(payload, "name", ""),
    detail: get(payload, "detail", ""),
    images: get(payload, "images", ""),
    files: get(payload, "files", ""),
    manufacturer: get(payload, "manufacturer", ""),
    description: get(payload, "description", ""),
    sku: get(payload, "sku", ""),
    vendorSku: get(payload, "vendorSku", ""),
    mfgSku: get(payload, "mfgSku", ""),
    slug: get(payload, "slug", ""),
    vendor: get(payload, "vendor", {}),
    registrationRequired: get(payload, "registrationRequired", false),
    boundByState: get(payload, "boundByState", false),
    categorySlug: get(payload, "categorySlug", ""),
    subcategorySlug: get(payload, "subcategorySlug", ""),
    weight: get(payload, "weight", 0),
    weightTypeId: get(payload, "weightTypeId", 1),
    cardVolume: get(payload, "cardVolume", ""),
    limited: get(payload, "limited", false),
    limitedQty: get(payload, "limitedQty", ""),
    minQtyForPurchase: get(payload, "minQtyForPurchase", 0),
    marketplaceStoreFront: get(payload, "marketplaceStoreFront", true),
    practiceStoreFront: get(payload, "practiceStoreFront", false),
    childProducts: get(payload, "childProducts", ""),
    productBundle: get(payload, "productBundle", ""),
    isBundle: isBundle,
    rateType: get(payload, "rateType", "C"),
    labtestId: get(payload, "labtestId"),
    labFee: get(payload, "labFee", ""),
    isVisibleMedcenter: get(payload, "isVisibleMedcenter", false),
    isVirtualProduct: get(payload, "isVirtualProduct", false),
    inStock: get(payload, "inStock", true),
    productShippingClassLevel: get(payload, "productShippingClassLevel", null),
    // 503A FIELDS
    drugType: get(payload, "drugType"),
    strength: get(payload, "strength"),
    showMfgSig: get(payload, "showMfgSig"),
    mfgSig,
    dispenseTypeId: get(payload, "dispenseTypeId"),
    size: get(payload, "size"),
    productUomId: get(payload, "productUomId"),
    productBaseId: get(payload, "productBaseId"),
    refillLimit,
    days,
    isPreorder: get(payload, "isPreorder"),
  };
};

export default {
  constructProductPayload,
  initProductData,
};
