import vendorModule from "./vendorModule/actions";
import marketplaceModuleActions from "./marketplaceModule/actions";
import marketModuleActions from "./marketModule/actions";
import lookupModuleActions from "./lookupModule/actions";

export default {
  ...vendorModule,
  ...marketplaceModuleActions,
  ...marketModuleActions,
  ...lookupModuleActions,
};
