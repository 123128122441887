import moment from "moment";
import axios from "@/lib/axios";

export default {
  state: {
    analytics: [
      {
        // each object represents the analytics for that profile
        searchResults: [],
        profileViews: [],
        contactSubmits: [],
      },
    ],
  },

  getters: {
    getOrgAnalytics: (state) => {
      return state.analytics;
    },
    getOrgAnalyticsMonth: (state, getters) => {
      const date = new Date();
      const cutOff = moment(date).subtract(30, "days");
      const analytics = {
        searchResults: [],
        profileViews: [],
        contactSubmits: [],
      };
      getters.getOrgAnalytics.forEach((profile) => {
        const searchResults = profile.searchResults.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        const profileViews = profile.profileViews.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        const contactSubmits = profile.contactSubmits.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        analytics.searchResults = [...analytics.searchResults, ...searchResults];
        analytics.profileViews = [...analytics.profileViews, ...profileViews];
        analytics.contactSubmits = [...analytics.contactSubmits, ...contactSubmits];
      });
      return analytics;
    },
    getOrgAnalyticsWeek: (state, getters) => {
      const date = new Date();
      const cutOff = moment(date).subtract(7, "days");
      const analytics = {
        searchResults: [],
        profileViews: [],
        contactSubmits: [],
      };
      getters.getOrgAnalytics.forEach((profile) => {
        const searchResults = profile.searchResults.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        const profileViews = profile.profileViews.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        const contactSubmits = profile.contactSubmits.filter((event) => {
          const eventDate = new Date(event.createdAt);
          return eventDate >= cutOff;
        });
        analytics.searchResults = [...analytics.searchResults, ...searchResults];
        analytics.profileViews = [...analytics.profileViews, ...profileViews];
        analytics.contactSubmits = [...analytics.contactSubmits, ...contactSubmits];
      });
      return analytics;
    },
  },

  mutations: {
    setOrgAnalytics(state, payload) {
      state.analytics = payload;
    },
    setLandingPageResults(state, payload) {
      state.landingPageViewSubmits = payload.landingPageViewSubmits;
    },
  },

  actions: {
    fetchAnalyticsByOrg: async ({ commit, getters }) => {
      const response = await axios.get(`/directory/v1/analytics/org/${getters.getOrgId}`);
      commit("setOrgAnalytics", response.data);
    },
  },
};
