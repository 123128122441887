const getDispenseType = (state) => {
  return state.dispenseTypeLookup;
};
const getDispenseBase = (state) => {
  return state.dispenseBaseLookup;
};
const getUOM = (state) => {
  return state.UOMLookup;
};

export default {
  getDispenseType,
  getDispenseBase,
  getUOM,
};
