import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const addUpdateComment = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `/orders/v1/barcode`;

  const response = await proxy.execute(query, payload, {});
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  addUpdateComment,
};
