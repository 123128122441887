import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchActivationCodeDetails = async (_, activationCode) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/activatekit/${activationCode}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchActivationCodeDetails,
};
