import axios from '@/lib/axios';

const defaultState = () => {
  return {
    id: '',
    name: '',
    vin: '',
    description: '',
    addressId: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    phone: '',
    fax: '',
    email: '',
    orderEmail: '',
    website: '',
    logoLocation: '',
    returnPolicy: '',
    handlingTime: 0,
    orderCutoff: '',
    orderCutoffTz: '',
    shipsTo: [
      // {
      //   code: '',
      //   name: '',
      // },
    ],
    marketFee: '',
    active: false,
    marketFeeType: '',
    activeUsers: [
      // {
      //   id: '',
      //   firstName: '',
      //   lastName: '',
      //   email: '',
      //   phone: null,
      //   deletedAt: null,
      // },
    ],
    shippingCarrier: '',
    shippingType: '',
  };
};

export default {
  namespaced: true,

  state: {
    vendor: defaultState(),
    pharmaResults: [],
    medicalEquipmentResults: [],
    PharmaPage: 1,
    MedicalEquipmentPage: 1,
    pageLength: 20,
    vendorId: '',
  },

  getters: {
    getVendorId: (state) => {
      return state.vendorId;
    },
    getVendor: (state) => {
      return state.vendor;
    },
    getVendorPharmaProducts: (state) => {
      return state.pharmaResults;
    },
    getVendorMedicalEquipments: (state) => {
      return state.medicalEquipmentResults;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getVendorPharmaProductsPage(state, getters) {
      const page = getters.getPharmaPage;
      const results = getters.getVendorPharmaProducts;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getVendorMedicalEquipmentsPage(state, getters) {
      const page = getters.getMedicalEquipmentPage;
      const results = getters.getVendorMedicalEquipments;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getNumberOfVendorPharmaProductsPages(state, getters) {
      const pages = Math.ceil(
        getters.getVendorPharmaProducts.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfVendorMedicalEquipmentsPages(state, getters) {
      const pages = Math.ceil(
        getters.getVendorMedicalEquipments.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPharmaPage(state) {
      return state.PharmaPage;
    },
    getMedicalEquipmentPage(state) {
      return state.MedicalEquipmentPage;
    },
  },

  mutations: {
    setVendorPharmaProducts(state, payload) {
      state.pharmaResults = payload;
    },
    setVendorMedicalEquipments(state, payload) {
      state.medicalEquipmentResults = payload;
    },
    setVendor(state, payload) {
      state.vendor = payload;
    },
    clearVendor(state) {
      Object.assign(state.vendor, defaultState);
    },
    setMedicalEquipmentPage(state, payload) {
      state.MedicalEquipmentPage = payload;
    },
    setPharmaPage(state, payload) {
      state.PharmaPage = payload;
    },
    setVendorId(state, payload) {
      state.vendorId = payload;
    },
  },

  actions: {
    nextPharmaPage({ getters, commit }) {
      commit('setPharmaPage', getters.getPharmaPage + 1);
    },
    prevPharmaPage({ getters, commit }) {
      commit('setPharmaPage', getters.getPharmaPage - 1);
    },
    commitPharmaPage({ commit }, payload) {
      commit('setPharmaPage', payload);
    },

    nextMedicalEquipmentPage({ getters, commit }) {
      commit('setMedicalEquipmentPage', getters.getMedicalEquipmentPage + 1);
    },
    prevMedicalEquipmentPage({ getters, commit }) {
      commit('setMedicalEquipmentPage', getters.getMedicalEquipmentPage - 1);
    },
    commitMedicalEquipmentPage({ commit }, payload) {
      commit('setMedicalEquipmentPage', payload);
    },
    fetchVendorByVin: async ({ commit }, vendorId) => {
      const response = await axios.get(
        `/accounts/v1/market/vendor/vin/${vendorId}`,
      );
      commit('setVendor', response.data);
    },
    fetchVendorPharmaProducts: async ({ commit }) => {
      const response = await axios.get(
        '/products/v1/market/recent',
      );
      commit('setVendorPharmaProducts', response.data);
    },
    fetchVendorMedicalEquipments: async ({ commit }) => {
      const response = await axios.get(
        '/products/v1/market/recent',
      );
      commit('setVendorMedicalEquipments', response.data);
    },
    commitClearVendor: async ({ commit }) => {
      commit('clearVendor');
    },
  },
};
