import { get } from 'lodash';
import axios from '@/lib/axios';
import notification from '@/utils/notifications';

const downloadPatientForm = async ({ commit }, payload) => {
  try {
    let fileName = get(payload, "fileName", "");
    // "simpatra-practice-builder/patient-forms" - default bucket for patient forms
    let bucketName = get(payload, "bucketName", "simpatra-practice-builder/patient-forms");
    let postPayload = {
      bucketName: bucketName,
      fileName: fileName
    };
    const response = await axios.post(
      `accounts/v1/outreach/download`,
      postPayload
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    notification('warning', error.response.data.message);
  }
};

export default {
  downloadPatientForm,
};