import moment from 'moment';
import axios from '@/lib/axios';

export default {
  state: {
    step: 0,
    accountIsSetup: false,
    showWelcome: true,
  },

  getters: {
    // getOrgAnalytics: (state) => {
    //   return state.analytics;
    // },
    getAccountIsSetup(state, getters, rootState) {
      return rootState.org.details.accountSetup;
    },
    getStep(state) {
      return state.step;
    },
    getShowWelcome(state) {
      return state.showWelcome;
    },
  },

  mutations: {
    setStep(state, payload) {
      if (state.step++ > 2) {
        state.step = 0;
      }
    },
  },

  actions: {

  },
};
