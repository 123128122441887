import axios from '../lib/axios';

export default {
  state: {
    details: {
      dividends: [],
      id: '',
      isActive: false,
      locationId: '',
      points: 0,
      redemptions: null,
      subsctractPrice: 0,
    },
  },

  getters: {
    getLoyaltyDollars: (state) => {
      return (state.details.points / 100).toFixed(2);
    },
    getSubstractedPrice: (state) => {
      // return (state.details.points / 100).toFixed(2);
      return state.details.getSubstractedPrice;
    },
    getLoyalty: (state) => {
      return state.details;
    },
  },

  mutations: {
    setLoyalty(state, payload) {
      state.details = payload;
    },
    substractLoyaltyBalance(state, payload) {
      state.details = payload;
    },
  },

  actions: {
    fetchLocationLoyaltyPoints: async ({ getters, commit }) => {
      const response = await axios.get(
        `/loyalty/v1/balance/location/${localStorage.currentLocation}`,
      );
      commit('setLoyalty', response.data);
    },
    substractLoyalty: ({ getters, commit }) => {
      commit('substractLoyaltyBalance', getters.getLoyaltyDollars);
    },
  },
};
