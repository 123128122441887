import Vue from 'vue';
import axios from '@/lib/axios';

export default {
  namespaced: true,

  state: {
    carousel: {
      // 'category-slug': [...products]
    },
  },

  getters: {
    getCarousels: (state) => {
      return state.carousel;
    },
  },

  mutations: {
    setCarouselProducts(state, payload) {
      /*
      Use Vue.set to handle property addition/deletion:
      https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      */
      Vue.set(state.carousel, payload.categorySlug, payload.products);
    },
  },

  actions: {
    fetchProductsBySlug: async ({ commit }, categorySlug) => {
      const response = await axios.get(
        `/products/v1/market/category/${categorySlug}`,
      );
      commit('setCarouselProducts', { categorySlug, products: response.data });
    },
  },
};
