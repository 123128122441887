import axios from "@/lib/axios";
import notification from "@/utils/notifications";

const fetchAllSocialMediaList = async ({ commit }) => {
  try {
    const response = await axios.get("accounts/v1/outreach/fetchallsocialmedia");
    if (response) {
      const { data } = response;
      commit("setSocialMediaList", data);
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

export default {
  fetchAllSocialMediaList,
};
