import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchPaginatedOrdersByPatientId = async ({ commit }, params) => {
  const proxy = new GetSimpatraServiceProxy();
  let queryParams = {};
  if (params.queryParams) {
    queryParams = params.queryParams;
  }
  const query = `orders/v1/patient/${params.patientId}/invoice/pagenumber/${params.pageNumber}/pagesize/${params.pageSize}`;
  const { data } = await proxy.execute(query, queryParams);
  commit("setPatientOrders", data);
};

export default {
  fetchPaginatedOrdersByPatientId,
};
