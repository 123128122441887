import { isEmpty, get } from "lodash";
import axios from "@/lib/axios";

export default {
  namespaced: true,

  state: {
    leads: [],
    formattedLeads: [],
    page: 1,
    contactPage: 1,
    pageLength: 5,
    contacts: [],
    filterContacts: [],
  },

  getters: {
    getOrgLeads: (state) => {
      return state.leads;
    },
    getFilteredContacts: (state) => {
      return state.filterContacts;
    },
    getOrgContacts: (state) => {
      return state.contacts;
    },
    getFormattedLeads: (state) => {
      return state.formattedLeads;
    },
    getNumberOfPages(state, getters) {
      const pages = Math.ceil(getters.getOrgLeads.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfContactPages(state, getters) {
      const pages = Math.ceil(getters.getFilteredContacts.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getContactPage(state) {
      return state.contactPage;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const leads = getters.getOrgLeads;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return leads.slice(a, b);
    },
    getContactPageResults(state, getters) {
      const page = getters.getContactPage;
      const contacts = getters.getFilteredContacts;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return contacts.slice(a, b);
    },
  },

  mutations: {
    setOrgLeads(state, payload) {
      state.leads = payload;
    },
    setFilteredContacts(state, payload) {
      state.filterContacts = payload;
    },
    setFormattedLeads(state, payload) {
      state.formattedLeads = payload;
    },
    setOrgContatcs(state, payload) {
      state.contacts = isEmpty(payload) ? [] : payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setContactPage(state, payload) {
      state.contactPage = payload;
    },
  },

  actions: {
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
    nextContactPage({ getters, commit }) {
      commit("setContactPage", getters.getContactPage + 1);
    },
    prevContactPage({ getters, commit }) {
      commit("setContactPage", getters.getContactPage - 1);
    },
    commitContactPage({ commit }, payload) {
      commit("setContactPage", payload);
    },
    fetchLeadsByOrg: async ({ commit, rootGetters }) => {
      const response = await axios.get(`/accounts/v1/org/${rootGetters.getOrgId}/typeform/responses`);
      // commit('setOrgLeads', response.data);
      commit("setFormattedLeads", response.data);
    },
    commitFilteredLeads({ commit }, payload) {
      commit("setOrgLeads", payload);
    },
    commitFilteredContacts({ commit }, payload) {
      commit("setFilteredContacts", payload);
    },
    fetchContactsUs: async ({ commit }, orgId) => {
      const response = await axios.get(`/accounts/v1/org/contactus/${orgId}`);
      if (response) commit("setOrgContatcs", get(response, "data", []));
    },
  },
};
