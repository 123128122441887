import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import outreachActions from "./actions/outreach";

const fetchDosingUsers = async (_, orgId) => {
  try {
    const response = await axios.get(`accounts/v1/org/${orgId}/dosingUsers`);
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

export default {
  ...outreachActions,
  fetchDosingUsers,
};
