// CONSTANTS
import STATUS_ID_CONSTANTS from "@/constants/status-id-constants";

const setPatientOrders = (state, payload) => {
  state.patientOrders = payload;
};

const updatePatientOrders = (state, payload) => {
  if (payload.type === "UPDATE") {
    const { INVOICE_STATUSES } = STATUS_ID_CONSTANTS;
    const { FULFILLMENT_STATUSES } = INVOICE_STATUSES;

    const activationCode = payload.result.activationCode;
    if (activationCode) {
      const orderIndex = state.patientOrders.content.findIndex((order) => order.generatedId === payload.generatedId);
      state.patientOrders.content[orderIndex].fulfillmentStatuses.kitFulfillment.activationCode = activationCode;
      state.patientOrders.content[orderIndex].fulfillmentStatuses.kitFulfillment.status =
        FULFILLMENT_STATUSES.KIT_REGISTERED.value;
    }
  } else {
    state.patientOrders.content.unshift(payload.result);
  }
};

export default {
  setPatientOrders,
  updatePatientOrders,
};
