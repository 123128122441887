import axios from "@/lib/axios";

export default {
  state: {
    subscription: {
      id: "",
      planPrice: 0,
      features: [],
      paymentMethodId: "",
    },
  },

  getters: {
    getOrgSubscription: (state) => {
      return state.subscription;
    },
  },

  mutations: {
    setOrgSubscription(state, payload) {
      if (payload.length > 0) {
        state.subscription = payload[0];
      }
    },
    setOrgSubscriptionPaymentMethod(state, payload) {
      state.subscription.paymentMethodId = payload;
    },
  },

  actions: {
    fetchOrgSubscriptions: async ({ commit, getters }) => {
      const response = await axios.get(`/subscription/v1/organization/${getters.getOrgId}`);
      commit("setOrgSubscription", response.data);
    },
    updateOrgSubscriptionPaymentMethod: async ({ dispatch, getters }) => {
      const response = await axios.put(
        `/subscription/v1/organization/${getters.getOrgId}/subscription/${getters.getOrgSubscription.id}`,
        {
          paymentMethodId: getters.getOrgSubscription.paymentMethodId,
        }
      );
      dispatch("fetchOrgSubscriptions");
    },
    commitOrgSubscriptionPaymentMethod: async ({ commit }, payload) => {
      commit("setOrgSubscriptionPaymentMethod", payload);
    },
  },
};
