import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

// @ PAYLOAD
const fetchShippingServicesLevel = async (_, queryParams) => {
  const proxy = new GetSimpatraServiceProxy();
  let carrier = "";
  if (queryParams) carrier = queryParams.carrier;

  const query = `shipping/v1/carriers`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return get(data, "carriers", []);
  }
};
export default {
  fetchShippingServicesLevel,
};
