import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const downloadSDIResult = async (_, fulfillmentId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/laboratory/sdi/fulfillment/${fulfillmentId}/pdf/download`;

  const response = await proxy.execute(
    query,
    null,
    {
      displaySuccessMessage: false,
      successNotificationMsg: "SDI result successfully downloaded!",
    },
    {
      responseType: "blob",
    }
  );

  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  downloadSDIResult,
};
