import axios from "@/lib/axios";
import { yearMonthDay } from "@/plugins/moment";

export default {
  namespaced: true,
  state: {
    vendors: [],
    orgs: [],
    allOrgsDetails: [],
    currentOrg: {
      details: {
        paymentWithoutCc: false,
      },
      availableCards: [],
      dosingDetails: {
        enabled: false,
        freeDoses: 0,
        maxDoses: -1,
        numDosingUsers: 0,
        pricePerDose: 39.99,
        paymentMethodId: "",
        billingGracePeriod: 30,
      },
      subscription: {
        nextBillDue: "",
        planId: null,
        planPrice: "",
        billingPeriod: "",
        billingPeriodType: "",
        features: [],
      },
    },
    orgManagementPage: 1,
    orgManagementPageLength: 25,
  },

  getters: {
    getAllVendors: (state) => {
      return state.vendors;
    },
    getAllOrgs: (state) => {
      return state.orgs;
    },
    getAdminAllOrgDetails: (state) => {
      return state.allOrgsDetails;
    },
    getCurrentAdminOrg: (state) => {
      return state.currentOrg.details;
    },
    getCurrentAdminOrgCards: (state) => {
      return state.currentOrg.availableCards;
    },
    getCurrentAdminOrgSubscription: (state) => {
      return state.currentOrg.subscription;
    },
    getCurrentAdminOrgDosingDetails: (state) => {
      return state.currentOrg.dosingDetails;
    },
    getNumberOfPages(state, getters) {
      const pages = Math.ceil(getters.getAdminAllOrgDetails.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.orgManagementPageLength;
    },
    getPage(state) {
      return state.orgManagementPage;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getAdminAllOrgDetails;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setOrgPage(state, payload) {
      state.orgManagementPage = payload;
    },
    setAdminVendors(state, payload) {
      state.vendors = payload;
    },
    setAdminOrgs(state, payload) {
      state.orgs = payload;
    },
    setAdminOrgDetails(state, payload) {
      state.currentOrg.details = payload;
    },
    setAdminAllOrgDetails(state, payload) {
      state.allOrgsDetails = payload;
    },
    setCurrentAdminOrgSubscription: (state, payload) => {
      state.currentOrg.subscription = payload;
    },
    setCurrentAdminOrgCards: (state, payload) => {
      state.currentOrg.availableCards = payload;
    },
    setCurrentAdminOrgDosingDetails: (state, payload) => {
      if (payload) {
        state.currentOrg.dosingDetails = payload;
        state.currentOrg.dosingDetails.enabled = true;
      } else {
        state.currentOrg.dosingDetails = {
          enabled: false,
          freeDoses: 0,
          maxDoses: -1,
          numDosingUsers: 0,
          pricePerDose: 39.99,
          paymentMethodId: "",
          billingGracePeriod: 30,
        };
      }
    },
  },
  actions: {
    nextPage({ getters, commit }) {
      commit("setOrgPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setOrgPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setOrgPage", payload);
    },
    toggleOrgContext: async ({ commit }, payload) => {
      const { currentOrgId } = payload;
      const body = {
        userIdList: payload.userIdList,
        newOrgId: payload.orgId,
      };
      const response = axios.put(`/accounts/v1/admin/migrate/${currentOrgId}`, body);
      // commit('setAdminOrgs', response.data);
    },
    adminFetchAllVendors: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/vendor");
      commit("setAdminVendors", response.data);
    },
    adminFetchAllOrgs: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/org");
      commit("setAdminOrgs", response.data);
    },
    adminFetchOrgDetails: async ({ commit }, orgId) => {
      const response = await axios.get(`/accounts/v1/org/${localStorage.orgId ? localStorage.orgId : orgId}`);

      commit("setAdminOrgDetails", response.data);
      commit("setCurrentAdminOrgDosingDetails", response.data.dosing);

      return response.data;
    },
    adminUpdateOrgDetails: async ({ commit }, payload) => {
      const orgId = payload.id;
      const response = await axios.put(`/accounts/v1/org/${orgId}`, payload);
      commit("setAdminOrgDetails", response.data);
    },
    adminFetchAllOrgDetails: async ({ commit }) => {
      const response = await axios.get(
        "/accounts/v1/org/all"
        // '/accounts/v1/demo/org/all?pageNo=0',
      );
      commit("setAdminAllOrgDetails", response.data);
      // commit('setCurrentAdminOrgDosingDetails', response.data.dosing);
    },
    commitCurrentAdminOrgDosingDetails: ({ commit }, payload) => {
      commit("setCurrentAdminOrgDosingDetails", payload);
    },
    commitCurrentAdminOrgSubscription: ({ commit }, payload) => {
      commit("setCurrentAdminOrgSubscription", payload);
    },
    adminFetchAllOrgCardAccounts: async ({ commit, getters }, orgId) => {
      const orgIdVal = orgId ? orgId : getters.getCurrentAdminOrg.id;
      const response = await axios.get(`/payments/v1/card/buyer/${orgIdVal}/method`);
      commit("setCurrentAdminOrgCards", response.data);
      return response.data;
    },
    updateOrganizationDosingDetails: async ({ commit, getters }, details) => {
      // const orgId = getters.getCurrentAdminOrg.id;
      // const details = getters.getCurrentAdminOrgDosingDetails;
      const { orgId } = details;
      const body = {
        enabled: details.dosing.enabled,
        // enabled: true,
        freeDoses: details.dosing.freeDoses,
        maxDoses: details.dosing.maxDoses,
        numDosingUsers: details.dosing.numDosingUsers,
        pricePerDose: details.dosing.pricePerDose,
        billingGracePeriod: details.dosing.billingGracePeriod,
      };
      const response = await axios.put(`/accounts/v1/admin/organization/${orgId}/dosing`, body);
      commit("setCurrentAdminOrgDosingDetails", response.data.dosing);
    },
    updateCurrentOrgSubscription: async ({ commit, getters }) => {
      const orgId = getters.getCurrentAdminOrg.id;
      const currentSub = getters.getCurrentAdminOrgSubscription;
      currentSub.nextBillDue = yearMonthDay(currentSub.nextBillDue);
      const response = await axios.put(`/subscription/v1/admin/organization/${orgId}/subscription/`, currentSub);
      commit("setCurrentAdminOrgSubscription", response.data);
    },
    resetOrgSubscription: async ({ commit }) => {
      commit("setCurrentAdminOrgSubscription", {
        name: "",
        description: "",
        nextBillDue: "",
        planId: "",
        plan: {
          id: null,
          name: "",
          description: "",
          price: "",
          billingPeriod: "",
          billingPeriodType: "",
        },
        features: [],
      });
    },
    addNewVendor: async ({ commit }, payload) => {
      const body = {
        name: payload.name,
        phone: payload.phone,
        email: payload.email,
        vin: payload.vin,
        description: payload.description,
        marketFee: payload.marketFee,
        marketFeeType: payload.marketFeeType,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
        shippingCarrier: payload.shippingCarrier,
        shippingType: payload.shippingType,
        shippingTypeId: payload.shippingTypeId,
        orderEmail: payload.orderEmail,
        orderCutoff: "17:00",
        orderCutoffTz: "MST",
        handlingTime: "1",
        user: {
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          email: payload.user.email,
          primary: true,
        },
      };
      return axios.post("/accounts/v1/vendor/", body);
    },
    refundFulfillment: async ({ commit }, payload) => {
      const { vendorId, fulfillmentId } = payload;
      const body = {
        refundAmount: payload.refundAmount,
      };
      return axios.post(`/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/refund`, body);
    },
  },
};
