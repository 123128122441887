const setOrgCCList = (state, payload) => {
  if (payload.actionType && payload.actionType === "addOrgCC") {
    state.orgCCList.data.push(payload.data);
  } else {
    state.orgCCList.data = payload;
  }
};

export default {
  setOrgCCList,
};
