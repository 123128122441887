import { merge } from "lodash";
import vendorModuleMutations from "./vendorModule/mutations";
import buyerModuleMutations from "./buyerModule/mutations";
import patientModuleMutations from "./patient/mutations";
import activationCodeModuleMutations from "./activationCodeModule/mutations";
import ordersModuleMutations from "./ordersModule/mutations";
import orgModuleMutations from "./orgModule/mutations";
import cartModuleMutations from "./cartModule/mutations";

export default merge(
  vendorModuleMutations,
  buyerModuleMutations,
  patientModuleMutations,
  activationCodeModuleMutations,
  ordersModuleMutations,
  orgModuleMutations,
  cartModuleMutations
);
