import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

/**
 * USING DECODED DATA IN THE TOKEN
 * This is for security purpose
 */
const initializeCurrentLoggedInInfo = ({ commit }, payload) => {
  commit("setCurrentLoggedInInfo", payload);
}


const fetchLoggedUserAccessibleLocations = async (_, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  // orgId query only works for super admin whenever user is trying to access other's org
  const query = `accounts/v1/user/location-access`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

// user/location-access
export default {
  initializeCurrentLoggedInInfo,
  fetchLoggedUserAccessibleLocations
}