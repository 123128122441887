const setDispenseType = (state, payload) => {
  state.dispenseTypeLookup = payload;
};
const setDispenseBase = (state, payload) => {
  state.dispenseBaseLookup = payload;
};
const setUOM = (state, payload) => {
  state.UOMLookup = payload;
};

export default {
  setDispenseType,
  setDispenseBase,
  setUOM,
};
