const getCartPreOrdersData = (state) => {
  return state.preOrdersCart.data;
};

const getCartPreOrdersPaginationDetails = (state) => {
  return state.preOrdersCart.paginationDetails;
};

export default {
  getCartPreOrdersData,
  getCartPreOrdersPaginationDetails,
};
